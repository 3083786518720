import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {Col, Dropdown} from "react-bootstrap";
import CountryDropdownItem from "./CountryDropdownItem";
let countryJson = require("../../assets/json/countries.json");

const CountryDropdown = (props) => {

    let streamStatistics = props.statisticsReducer.StreamStatistics;
    let checkedSongIds = props.checkedSongIds;
    let [checkedCountryCodes, setCheckedCountryCodes] = useState([]);
    let [selectedCountries, setSelectedCountries] = useState("All Countries");
    let [allCountriesText, setAllCountriesText] = useState("All Countries")

    // Set danh sach country da chon
    useEffect(() => {
        let checks = [];
        // Set danh sach country check
        (props.statisticsReducer.Countries||[]).map((countryCode) => {
            checks.push(countryCode)
        })
        setCheckedCountryCodes(checks);
    }, [props.statisticsReducer.Countries])

    // Set ten country da chon
    useEffect(() => {
        let checkText;
        if (checkedCountryCodes.length === 1) {
            countryJson.forEach(country => {
                if (country.code == checkedCountryCodes[0]) {
                    checkText = `${country.name}`;
                }
            })
        } else if (checkedCountryCodes.length < (props.statisticsReducer.Countries||[]).length) {
            checkText = `${checkedCountryCodes.length} Countries`
        } else {
            checkText = `All Countries (${checkedCountryCodes.length})`;
        }
        setSelectedCountries(checkText);

        // Set text all countrys
        if (checkedCountryCodes.length > 0) {
            setAllCountriesText("None Countries (0)");
        } else {
            setAllCountriesText(`All Countries (${countAllSongInCountryWhenSongChange()})`);
        }
        // Goi callback
        props.onCheckCountries(checkedCountryCodes);
    }, [checkedCountryCodes, checkedSongIds])


    // Dem tat ca so luong song trong country khi chon song
    let countAllSongInCountryWhenSongChange = () => {
        let counts = 0;
        // Tinh theo song da chon
        (props.statisticsReducer.Countries||[]).map(country => {
            counts += countSongInCountryWhenSongChange(country);
        });
        return counts;
    }

    // Dem so luong song trong country khi chon song
    let countSongInCountryWhenSongChange = (country) => {
        let count = 0;
        (Object.keys(streamStatistics)||[]).map(date => {
            (Object.keys(streamStatistics[date]) || []).map(songId => {
                // Tinh theo channel da chon
                if (checkedSongIds.includes(songId)) {
                    (Object.keys(streamStatistics[date][songId]) || []).map(countryCode => {
                        if (countryCode == country) {
                            count += Number(streamStatistics[date][songId][countryCode]);
                        }
                    });
                }
            });
        });
        return count;
    }

    // Chon 1 country
    let checkCountry = (countryCode) => {
        if (props.statisticsReducer.Countries != null && checkedCountryCodes != null) {
            let ct = checkedCountryCodes.slice(0);
            if (ct.includes(countryCode)) {
                ct.splice(ct.indexOf(countryCode), 1);
            } else {
                ct.push(countryCode);
            }
            setCheckedCountryCodes(ct);
        }
    }

    // Chon tat ca country
    let checkAllCountries = (e) => {
        e.preventDefault();
        let isCheckAll = true;
        if (checkedCountryCodes.length > 0) {
            isCheckAll = false;
        }
        if (isCheckAll) {
            // Chon tat ca country
            let checks = [];
            // Set danh sach country check
            (props.statisticsReducer.Countries||[]).map((countryCode) => {
                checks.push(countryCode)
            })
            setCheckedCountryCodes(checks);
        } else {
            // Khong chon country nao
            setCheckedCountryCodes([]);
        }
    }

    // Kiem tra xem co chon country nay khong
    let isCountryChecked = (countryCode) => {
        if (checkedCountryCodes != null) {
            return checkedCountryCodes.includes(countryCode);
        } else {
            return false;
        }
    }

    let countryDropdownItems = () => {
        let countryDropdownResult = [];
        // Dem so luong cua moi country
        (props.statisticsReducer.Countries||[]).forEach(country => {
            let count = countSongInCountryWhenSongChange(country);
            let countryDropdown = {};
            countryDropdown.count = count;
            countryDropdown.item = <CountryDropdownItem key={`${country} ${isCountryChecked(country)}`}
               value={country} checked={isCountryChecked(country)} count={count} onChecked={(country) => checkCountry(country)}/>;
            countryDropdownResult.push(countryDropdown);
        });
        // Sap xep theo luot view
        countryDropdownResult = countryDropdownResult.slice(0).sort((b, a) => {
            let columnA = a["count"];
            let columnB = b["count"];
            if (columnA === columnB) {
                return 0
            } else if (columnA < columnB) {
                return -1
            } else {
                return 1
            }
        })
        return countryDropdownResult.map((countryDropdown) => countryDropdown.item);
    }

    return <Col className="form-group">
        <label className="col-form-label font-weight-bold pl-0">Country</label>
        <div className="">
            <Dropdown>
                <Dropdown.Toggle variant="outline-secondary d-flex justify-content-between align-items-center" id="language-dropdown" className="form-control">
                    {selectedCountries}
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="w-100 overflow-x-hidden-y-auto max-h-300px p-0">
                    <div className="btn btn-hover-light p-3 align-items-center d-flex"
                         onClick={(e)=>{checkAllCountries(e)}}>{allCountriesText}</div>
                    {countryDropdownItems()}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    </Col>
}

const mapStateToProps = (state) => {
    return {
        statisticsReducer:state.statisticsReducer,
    };
};

export default connect(mapStateToProps)(CountryDropdown);
