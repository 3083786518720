import ApiService from "../../services/apiService"
import {TokenKey, TokenService} from "../../services/storageService";
import {ActionType, FollowAction} from "../constants";

export function getFollowings() {
    return async function (dispatch, getState) {
        let response = await sendFollowApiRequest(getState, "Following");
        if (response && response.data && Array.isArray(response.data.Following)) {
            dispatch({
                type: FollowAction.GET_FOLLOWINGS,
                payload: response.data.Following,
            });
        }
    }
}

export function getFollowers() {
    return async function (dispatch, getState) {
        let response = await sendFollowApiRequest(getState, "Follower");
        if (response && response.data && Array.isArray(response.data.Follower)) {
            dispatch({
                type: FollowAction.GET_FOLLOWERS,
                payload: response.data.Follower,
            });
        }
    }
}

export function unfollow(followId) {
    return async function (dispatch, getState) {
        let response = await sendFollowApiRequest(getState, "Unfollow", {FollowId: followId});
        if (response && response.data?.Result === 1) {
            dispatch({
                type: FollowAction.UNFOLLOW,
                payload: {
                    followId,
                },
            });
        }
    }
}

export function follow(followId) {
    return async function (dispatch, getState) {
        let response = await sendFollowApiRequest(getState, "Follow", {FollowId: followId});
        if (response && response.data?.Result === 1) {
            dispatch({
                type: FollowAction.FOLLOW,
                payload: {
                    followId,
                },
            });
        }
    }
}

function sendFollowApiRequest(getState, action, params = {}) {
    return new Promise(resolve => {
        // action: Follow|Unfollow|Following|Follower ( theo dõi|bỏ theo dõi|lấy danh sách người mình đang theo dõi|lấy danh sách người đang theo dõi mình
        ApiService.request({
            url: "/social/follow.php",
            method: "get",
            params: {
                UserId: getState().accountReducer?.User?.UserId,
                Token: TokenService.getToken(),
                AppVersion: "1.0",
                AppId: "com.liveplayer.v6",
                Action: action,
                ...params,
            }
        }).then(response => {
            resolve(response);
        }).catch(e => {
            console.warn(e);
            resolve(null);
        });
    })
}
