import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Link, useHistory, useParams, withRouter} from "react-router-dom";
import {useLocation} from 'react-router-dom'
import {PAGINATION_DEFAULT} from "../../store/constants";

const KeenPagination = withRouter((props) => {
    let history = useHistory();
    let location = useLocation();
    let query = useQuery();

    let [currentPage, setCurrentPage] = useState(Number(query.get("page")) || 1);
    let [perPage, setPerPage] = useState(query.get("per_page") || PAGINATION_DEFAULT.ITEM_PER_PAGE);
    let [totalPage, setTotalPage] = useState(Math.ceil(props.totalItems / perPage));

    useEffect(() => {
        let totalPage = Math.ceil(props.totalItems / perPage)
        setTotalPage(totalPage);
        if (totalPage < currentPage) {
            searchParam.set("page", "1");
            history.push({
                ...location,
                search: `?${searchParam.toString()}`
            })
        }
    }, [props.totalItems, perPage]);

    let [searchParam, setSearchParam] = useState(new URLSearchParams(location.search.replace("?", "")));
    useEffect(() => {
        setSearchParam(new URLSearchParams(location.search.replace("?", "")));
        setCurrentPage(Number(query.get("page")) || 1);
        setPerPage(query.get("per_page") || PAGINATION_DEFAULT.ITEM_PER_PAGE);
    }, [location]);

    return <>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex flex-wrap mr-3">
                <Link to={(() => {
                    searchParam.set("page", 1);
                    return {
                        ...location,
                        search: `?${searchParam.toString()}`
                    }
                })()}
                   className={`btn btn-icon btn-sm btn-light-primary mr-2 my-1 ${currentPage === 1 ? "disabled" : ""}`}>
                    <i className="fas fa-angle-double-left"/>
                </Link>
                <Link to={(() => {
                    searchParam.set("page", currentPage - 1);
                    return {
                        ...location,
                        search: `?${searchParam.toString()}`
                    }
                })()}
                   className={`btn btn-icon btn-sm btn-light-primary mr-2 my-1 ${currentPage === 1 ? "disabled" : ""}`}>
                    <i className="fas fa-angle-left"/>
                </Link>
                {
                    pagination(currentPage, totalPage).map(pageNumber => {
                        searchParam.set("page", pageNumber);
                        return <Link
                            key={pageNumber}
                            to={{
                                ...location,
                                search: `?${searchParam.toString()}`
                            }}
                            className={`btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1 ${pageNumber === currentPage ? "active" : ""}`}>{pageNumber}</Link>
                    })
                }
                <Link to={(() => {
                    searchParam.set("page", currentPage + 1);
                    return {
                        ...location,
                        search: `?${searchParam.toString()}`
                    }
                })()}
                      className={`btn btn-icon btn-sm btn-light-primary mr-2 my-1 ${currentPage === totalPage ? "disabled" : ""}`}>
                    <i className="fas fa-angle-right"/>
                </Link>
                <Link to={(() => {
                    searchParam.set("page", totalPage);
                    return {
                        ...location,
                        search: `?${searchParam.toString()}`
                    }
                })()}
                   className={`btn btn-icon btn-sm btn-light-primary mr-2 my-1 ${currentPage === totalPage ? "disabled" : ""}`}>
                    <i className="fas fa-angle-double-right"/>
                </Link>
            </div>
            <div className="d-flex align-items-center">
                <select
                    className="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary"
                    style={{
                        width: 75
                    }}
                    value={perPage}
                    onChange={e => {
                        searchParam.set("page", currentPage);
                        searchParam.set("per_page", e.target.value);
                        history.push({
                            ...location,
                            search: `?${searchParam.toString()}`
                        })
                    }}
                >
                    {[10, 20, 30, 50, 100].map(v => {
                        return <option value={v} key={v}>{v}</option>
                    })}
                </select>
                <span
                    className="text-muted">Showing {props.totalItems - (perPage * (currentPage - 1)) >= perPage ? perPage : props.totalItems - (perPage * (currentPage - 1))} of {props.totalItems} records</span>
            </div>
        </div>
    </>
})

KeenPagination.propTypes = {
    totalItems: PropTypes.number.isRequired,
};

KeenPagination.defaultProps = {
    totalItems: 0,
};

// Implementation in ES6
function pagination(c, m) {
    let current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

    for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || i >= left && i < right) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default KeenPagination;
