import React from 'react'
import {connect} from "react-redux";

const SearchStream = (props) => {
    return (
        <>
            <tr data-row={props.index + 1}>
                <td data-field="Code" className="align-middle">
                    <span>
                        <span className="text-secondary">{props.data.Code}</span>
                    </span>
                </td>
                <td data-field="Name" className="min-w-100px align-middle text-break">
                    <span>
                        <div className="text-dark font-size-lg mb-0">{props.data.Name}</div>
                    </span>
                </td>
                <td data-field="Link" className="min-w-250px align-middle text-break">
                    <span>
                        <span className="text-primary font-size-lg mb-0">{props.data.URL&&props.data.URL}</span>
                    </span>
                </td>
                <td data-field="Language" className="min-w-100px align-middle">
                    <span>
                        <div className="text-secondary font-size-lg mb-0">{props.data.Language}</div>
                    </span>
                </td>
                <td data-field="Views" className="min-w-100px align-middle">
                    <span>
                        <div className="text-secondary font-size-lg mb-0">{props.data.ViewByAll}</div>
                    </span>
                </td>
                <td data-field="Type" className="align-middle">
                    <span>
                       <div className="text-secondary font-size-lg mb-0 text-nowrap">{props.data.LastOnline}</div>
                    </span>
                </td>
            </tr>
        </>
    )
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps)(SearchStream);
