import {SearchStreamAction, PagingDisplay} from "../constants";

const initialState = {
    searchStream: "",
    currentIndex: 1,
    streams: [],
    currentDisplayingLimit: PagingDisplay.DISPLAY_LIMIT_100,
    streamsCount: PagingDisplay.DISPLAY_LIMIT_100,
    loading: false
};

// Xu ly cac su kien search stream
export default function searchStreamReducer(state = initialState, action) {
    switch (action.type) {
        case SearchStreamAction.LOADING: {
            return {
                ...state,
                ...action.payload,
                loading: true,
            }
        }
        case SearchStreamAction.CHANGE_DISPLAY_LIMIT: {
            return {
                ...state,
                ...action.payload,
                loading: true,
                currentIndex: 1,
                currentDisplayingLimit: action.payload
            }
        }
        case SearchStreamAction.CHANGE_CURRENT_INDEX: {
            return {
                ...state,
                ...action.payload,
                loading: true,
                currentIndex: parseInt(action.payload)
            }
        }
        case SearchStreamAction.GET_SEARCH_STREAM: {
            return {
                ...state,
                ...action.payload,
                loading: false,
                streamsCount: action.payload.count,
                streams: action.payload.streams,
            }
        }
        case SearchStreamAction.SEARCH_STREAM: {
            return {
                ...state,
                searchStream: action.payload
            }
        }
        default:
            return state;
    }
}
