import React, {Component} from "react";
import {Link} from "react-router-dom";
// import "./SidebarItem.scss";

class SidebarItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const item = this.props.item;
        const icon = item.icon;
        const title = item.title;

        if (item.dropdowns) {
            let isDropdownActive = false;
            for (const dropdown of item.dropdowns) {
                if (dropdown.active) {
                    isDropdownActive = true;
                    break;
                }
            }

            return (
                <li
                    className={`menu-item menu-item-submenu ${
                        isDropdownActive ? "menu-item-open" : ""
                    }`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <a
                        className="menu-link menu-toggle"
                        href="#"
                    >
                        <span className="svg-icon menu-icon">
                            <i className={`far ${icon} text-white`}/>
                        </span>
                        <span className="menu-text">{title}</span>
                        <i className="menu-arrow far fa-angle-right"/>
                    </a>
                    <div className="menu-submenu">
                        {/*<i className="menu-arrow far fa-angle-right"></i>*/}
                        <ul className="menu-subnav">
                            <li
                                className="menu-item menu-item-parent"
                                aria-haspopup="true"
                            >
                                <span className="menu-link">
                                    <span className="menu-text">{title}</span>
                                </span>
                            </li>
                            {item.dropdowns.map((item, index) => {
                                return (
                                    <SidebarDropdownItem
                                        key={index}
                                        item={item}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                </li>
            );
        } else {
            return (
                <li
                    className={`menu-item ${
                        item.active ? "menu-item-active" : ""
                    }`}
                    aria-haspopup="true"
                >
                    <Link
                        className="menu-link"
                        to={{
                            pathname: this.props.item.link || "#",
                        }}
                    >
                        <span className="svg-icon menu-icon">
                            <i className={`far ${icon} text-white`}/>
                        </span>
                        <span className="menu-text">{title}</span>
                    </Link>
                </li>
            );
        }
    }
}

class SidebarDropdownItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const item = this.props.item;
        return (
            <li
                className={`menu-item ${item.active ? "menu-item-active" : ""}`}
                aria-haspopup="true"
            >
                <Link
                    className="menu-link"
                    to={{
                        pathname: item.link || "",
                    }}
                >
                    <i className="menu-bullet menu-bullet-dot">
                        <span/>
                    </i>
                    <span className="menu-text">{item.title}</span>
                </Link>
            </li>
        );
    }
}

export default SidebarItem;
