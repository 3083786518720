import {ActionType, MessageAction} from "../constants";

/**
 *
 * @type {{messageList: {
  "MessageId": "74",
  "Message": "fjcjcj",
  "Time": "2014-05-06 07:19:28",
  "State": "3",
  "Tag": "2014-05-06 18:24:10",
  "FromUser": {
    "UserId": "40",
    "Nickname": "Chiến Nguyễn",
    "Avatar": "http://edge.mdcgate.com/livemedia/public/images/useravatar/02f052fbf0fa27f82cc645c44eae5ec9.jpg"
  },
  "ToUser": {
    "UserName": "Chiến Nguyễn",
    "UserId": "40",
    "Avatar": "http://edge.mdcgate.com/livemedia/public/images/useravatar/02f052fbf0fa27f82cc645c44eae5ec9.jpg"
  }
}}}
 */
const initialState = {
    messageList: [],
    sendMessageResult: false
};

// Xu ly cac su kien message
export default function messageReducer(state = initialState, action) {
    switch (action.type) {
        case MessageAction.GET_MESSAGES: {
            return {
                ...state,
                ...action.payload,
                messageList: action.payload
            }
        }
        case MessageAction.SEND_MESSAGE_RESULT: {
            return {
                ...state,
                ...action.payload,
                sendMessageResult: action.payload
            }
        }
        default:
            return state;
    }
}
