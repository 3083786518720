import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import "./assets/scss/style.bundle.css";
import "./assets/scss/index.scss";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import SignUp from "./view/pages/login/SignUp";
import Login from "./view/pages/login/Login";
import Layout from "./view/containers/Layout";

class App extends Component {

    render() {
        return (
            <Router basename={process.env.REACT_APP_BASE_URL}>
                <Switch>
                    <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>}/>
                    <Route exact path="/signup" name="Signup Page" render={props => <SignUp {...props}/>}/>
                    <Route path="/" name="MyStreams" render={props => <Layout {...props}/>}/>
                </Switch>
            </Router>
        );
    }
}

export default App;
