import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import ApiService from "../../services/apiService";
import {ActionType, TopEndToast} from "../../store/constants";
import Swal from "sweetalert2";
import {Link, useHistory} from "react-router-dom";
import {TokenService} from "../../services/storageService";
import {Button, Modal} from "react-bootstrap";
import ChannelThumb from "../../assets/images/bg_channel_thumb.png";

const UserChannel = (props) => {
    let history = useHistory();
    let userId = props.accountReducer.User.UserId;
    let songId = props.data.SongId;
    let group = props.group;

    // Them vao group
    const [showAddToModal, setShowAddToModal] = useState(false);
    const [showAddToDropdown, setShowAddToDropdown] = useState(false);
    const [selectedGroupToAdd, setSelectedGroupToAdd] = useState(null);
    const [groupsDropdown, setGroupsDropdown] = useState("");

    const selectAddGroupDropdownItem = (group) => {
        setShowAddToDropdown(false);
        setSelectedGroupToAdd(group);
    }

    useEffect(()=>{
        let result = "";
        (props.groupReducer.groups||[]).forEach((group, i) => {
            // Hien thi cac group trong dropdown
            result = [...result, <span className="dropdown-item" key={i}
               onClick={(e)=>selectAddGroupDropdownItem(group)}>{group.GroupName}</span>];
        });
        setGroupsDropdown (result);
    },[props.groupReducer.groups])

    const submitAddTo = async () => {
        const postData = {
            params: {
                Action: "AddStreamToGroup",
                UserId: userId,
                Token: TokenService.getToken(),
                StreamId: songId,
                GroupId: selectedGroupToAdd?.GroupId
            }
        };
        const {data} = await ApiService.getByUrl('/stream_v2/group.php', postData);
        if (data.Result === ActionType.RESULT_1) {
            // Lay lai danh sach user channel
            props.getUserChannels()
            // Hien thi thong bao them vao group thanh cong
            TopEndToast.fire({
                icon: 'success',
                title: `Add stream "${props.data.Name}" to group "${selectedGroupToAdd?.GroupName}" success!`
            });
            // An modal add di
            setShowAddToModal(false)
        } else {
            // Xoa stream that bai
            TopEndToast.fire({
                icon: 'error',
                title: `Add stream error!`
            });
        }
    }

    let addToModal = (
        <Modal centered show={showAddToModal} onHide={()=>{{
            setShowAddToModal(false);
            setShowAddToDropdown(false);
        }}}>
            <Modal.Header>
                <Modal.Title>Add to group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle w-100 d-flex justify-content-between align-items-center" type="button"
                            onClick={()=>{setShowAddToDropdown(!showAddToDropdown)}}
                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                        {(selectedGroupToAdd===null)?"Select a group":selectedGroupToAdd.GroupName}
                    </button>
                    <div className={`dropdown-menu w-100 ${showAddToDropdown&&"show"}`} aria-labelledby="dropdownMenuButton">
                        {groupsDropdown}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(e)=>{setShowAddToModal(false)}}>
                    Close
                </Button>
                <Button disabled={selectedGroupToAdd===null} variant="primary" onClick={(e)=>submitAddTo()}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    )

    // Xoa user channel
    const deleteUserChannel = async () => {
        // Hien thi thong bao co muon xoa user channel khong
        Swal.fire({
            heightAuto: false,
            title: `Do you want to delete stream "${props.data.Name}"?`,
            icon: "warning",
            showConfirmButton: true,
            confirmButtonColor: "#dd6b55",
            showCancelButton: true,
            confirmButtonText: `Delete`,
            cancelButtonText: `Cancel`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Dong y xoa
                const postData = {
                    params: {
                        UserId: userId,
                        Token: TokenService.getToken(),
                        StreamId: songId
                    }
                };
                const {data} = await ApiService.getByUrl('/stream_v2/delete_stream.php', postData);
                if (data.Result === ActionType.RESULT_SUCCESS) {
                    // Lay lai danh sach user channel
                    props.getUserChannels()
                    // Hien thi thong bao xoa stream thanh cong
                    TopEndToast.fire({
                        icon: 'success',
                        title: `Delete stream "${props.data.Name}" success!`
                    });
                } else {
                    // Xoa stream that bai
                    TopEndToast.fire({
                        icon: 'error',
                        title: `Delete stream error!`
                    });
                }
            }
        })
    }

    // Loai bo channel ra khoi group
    const removeStreams = () => {
        Swal.fire({
            heightAuto: false,
            title: `Do you want to remove stream "${props.data.Name}" from group "${group?.GroupName}"?`,
            icon: "warning",
            showConfirmButton: true,
            confirmButtonColor: "#dd6b55",
            showCancelButton: true,
            confirmButtonText: `Remove`,
            cancelButtonText: `Cancel`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Dong y loai bo
                const postData = {
                    params: {
                        Action: "RemoveStreams",
                        UserId: userId,
                        Token: TokenService.getToken(),
                        GroupId: group.GroupId,
                        StreamId: songId
                    }
                };
                const {data} = await ApiService.getByUrl('/stream_v2/group.php', postData);
                if (data.Result === ActionType.RESULT_1) {
                    // Lay lai danh sach user channel
                    props.getUserChannels()
                    // Hien thi thong bao xoa stream thanh cong
                    TopEndToast.fire({
                        icon: 'success',
                        title: `Delete stream "${props.data.Name}" success!`
                    });
                } else {
                    // Xoa stream that bai
                    TopEndToast.fire({
                        icon: 'error',
                        title: `Delete stream error!`
                    });
                }
            }
        })
    }

    return (
        <>
            <tr data-row={props.index + 1}>
                <td data-field="Code" className="align-middle">
                    <span className="text-secondary">{props.data.Code}</span>
                </td>
                <td data-field="Name" className="align-middle d-flex align-items-center">
                    <Link to={{
                        pathname: `/stream/${songId}`,
                    }}>
                        <div className="d-flex flex-row align-items-center min-w-150px">
                            <img alt="Channel Image" src={`${props.data.ImageURL ? props.data.ImageURL : ChannelThumb}`}
                                 onError={(e) => e.target.src = ChannelThumb}
                                 className="mr-3 w-100px"/>
                            <div className="text-primary font-size-lg text-break">{props.data.Name}</div>
                        </div>
                    </Link>
                </td>
                <td data-field="Link" className="min-w-250px align-middle text-break">
                    <span className="text-primary font-size-lg mb-0 cursor-pointer"
                        onClick={(e)=>window.open(props.data.URL)}>{props.data.URL}</span>
                </td>
                <td data-field="Language" className="align-middle text-nowrap">
                    <span className="text-secondary font-size-lg mb-0">
                        {props.data.Language}
                    </span>
                </td>
                <td data-field="Views" className="align-middle">
                    <span className="text-secondary font-size-lg mb-0">
                        {props.data.ViewByAll}
                    </span>
                </td>
                <td data-field="Type" className="align-middle">
                    <span className="text-secondary font-size-lg mb-0 text-nowrap">
                        {props.data.LastOnline}
                    </span>
                </td>
                <td data-field="Action" className="align-middle" style={{whiteSpace: "nowrap"}}>
                    <span onClick={(e)=>{history.push(`/stream/${songId}`)}}
                        className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                        title="Edit">
                        <i className="far fa-edit"/>
                    </span>

                    <span onClick={(e)=>deleteUserChannel()}
                          className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                          title="Delete">
                        <i className="far fa-trash-alt"/>
                    </span>

                    <span onClick={(e)=>{setShowAddToModal(true)}}
                          className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                          title="Add to group">
                        <i className="far fa-folder-plus"/>
                    </span>
                    {addToModal}

                    {group&&<span onClick={(e) => {removeStreams()}}
                           className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon ml-2"
                           title="Remove from group">
                        <i className="far fa-folder-times"/>
                    </span>}
                </td>
            </tr>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userChannelReducer:state.userChannelReducer,
        accountReducer:state.accountReducer,
        groupReducer: state.groupReducer,
    };
};

export default connect(mapStateToProps)(UserChannel);
