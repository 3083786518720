import React, {createRef, useEffect, useState} from 'react'
import {validateEmail, ActionType} from "../../../store/constants";
import {connect, useDispatch, useSelector} from 'react-redux'
import Swal from "sweetalert2";
import ApiService from "../../../services/apiService";
import {TokenKey, TokenService} from "../../../services/storageService";
import {Link, Redirect} from "react-router-dom";
import md5 from 'crypto-js/md5'
import signUpBackground from "../../../assets/images/sign_up_background.jpg"
import styled from "styled-components";
import Logo from "../../../assets/images/mdc_logo.png";

const FooterStyle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #9EA5BD;
`

const SignUp = (props) => {

    const dispatch = useDispatch();
    let focusNickname = createRef();

    useEffect(() => {
        focusNickname.current.focus();
    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();
        if (!validate(event)) return ;
        try {
            const {data} = await ApiService.getByUrl('/user/register_user.php', {
                params: {
                    Type: 0,
                    Nickname: nickname.trim(),
                    Email: email.trim(),
                    Password: md5(password).toString()
                }
            });
            if (data.Result === ActionType.RESULT_SUCCESS) {
                console.log(`Email ${email} signup success!`);
                // Hien thi thong bao dang ky thanh cong
                Swal.fire({
                    icon: 'success',
                    title: 'Signup success',
                    willClose(popup) {
                        // Set dang ky thanh cong
                        setSuccess(true)
                    }
                })
                // Luu lai thong tin dang ky
                TokenService.saveToken(email, TokenKey.EMAIL);
                TokenService.saveToken(password, TokenKey.PASSWORD);
                // Luu lai token
                TokenService.saveToken(data.user.Token);
                ApiService.setHeader();
                // Luu lai thong tin user
                dispatch({type: ActionType.LOGIN_SUCCESS, payload: data});
            } else {
                console.log(`Email ${email} signup error: ${data.Reason}`);
                Swal.fire({
                    icon: 'error',
                    title: data.Reason,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const validate = (event) => {
        let isValid = true;
        let nicknameTrim = nickname.trim();
        let emailTrim = email.trim();
        if (!nicknameTrim.match(".+") || /\s/.test(nicknameTrim)) {
            // Neu nickname trong hoac co khoang trang thi bao loi
            setNicknameError("Nickname is invalid")
            isValid = false;
        } else {
            setNicknameError("")
        }
        if (!validateEmail(email) || /\s/.test(emailTrim)) {
            setEmailError("Email is invalid")
            isValid = false;
        } else {
            setEmailError("")
        }
        if (!password.match(".+")) {
            setPasswordError("Password is invalid")
            isValid = false;
        } else {
            setPasswordError("")
        }
        if (rePassword !== password) {
            setRePasswordError("Password not match")
            isValid = false;
        } else {
            setRePasswordError("")
        }
        return isValid;
    }

    const [success, setSuccess] = useState(false);

    const [nickname, setNickname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");

    const [nicknameError, setNicknameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [rePasswordError, setRePasswordError] = useState("");

    if (props.accountReducer.isAuthenticated || success) {
        // Neu dang nhap thanh cong chuyen qua trang chu
        // Neu dang ky thanh cong thi tu dong dang nhap, chuyen qua trang chu
        return <Redirect to="/stream" />
    } else {
        return (
            <div className="d-flex flex-column justify-content-center main-root-content align-items-center"
                 style={{ backgroundImage: `url(${signUpBackground})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                <div className="card card-custom col-12 col-sm-6 col-md-4 col-xl-3 justify-content-center mx-auto">
                    <form onSubmit={onSubmit}>
                        <div className="card-body">
                            <h3 className="card-title mb-4">
                                Register an Account
                            </h3>
                            <div className="form-group mb-3">
                                <label className="mb-0" htmlFor="nickname">Nickname</label>
                                <input ref={focusNickname} type="nickname" className="form-control"
                                       id="nickname" placeholder="Nickname"
                                       onChange={(event)=>setNickname(event.target.value)}
                                       value={nickname}/>
                                <div className="fv-plugins-message-container">
                                    <div data-field="nickname" data-validator="notEmpty" className="fv-help-block">
                                        {nicknameError}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="mb-0" htmlFor="email">Email address</label>
                                <input type="email" className="form-control"
                                       id="email" placeholder="Email"
                                       onChange={(event)=>setEmail(event.target.value)}
                                       value={email}/>
                                <div className="fv-plugins-message-container">
                                    <div data-field="nickname" data-validator="notEmpty" className="fv-help-block">
                                        {emailError}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="mb-0" htmlFor="password">Password</label>
                                <input type="password" className="form-control" id="password"
                                       placeholder="Password" autoComplete="on"
                                       onChange={(event)=>setPassword(event.target.value)}
                                       value={password}/>
                                <div className="fv-plugins-message-container">
                                    <div data-field="nickname" data-validator="notEmpty" className="fv-help-block">
                                        {passwordError}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label className="mb-0" htmlFor="password">Retype Password</label>
                                <input type="password" className="form-control" id="rePassword"
                                       placeholder="Retype Password" autoComplete="on"
                                       onChange={(event)=>setRePassword(event.target.value)}
                                       value={rePassword}/>
                                <div className="fv-plugins-message-container">
                                    <div data-field="nickname" data-validator="notEmpty" className="fv-help-block">
                                        {rePasswordError}
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mr-2 btn-block">SignUp</button>

                            <div style={{textAlign: "center"}} className="mt-5">
                                <span className="text-dark-50">Already have an account? </span>
                                <Link to="/login" className="text-primary">Login</Link>
                            </div>
                        </div>
                    </form>
                </div>

                <FooterStyle>
                    <div className="Footer d-flex align-items-center mt-3">
                        <div className="container-fluid">
                            <div className="row">
                                <img alt="Logo" src={Logo} className="max-h-20px"/><div className="ml-3 text-white">© Copyright 2021 - MDC Media CO., LTD</div>
                            </div>
                        </div>
                    </div>
                </FooterStyle>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        // Gan account reducer vao props
        accountReducer:state.accountReducer
    };
};

export default connect(mapStateToProps) (SignUp);
