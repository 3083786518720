import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {follow, getFollowers, getFollowings, unfollow} from "../../../store/actions/followAction";
import {useLocation} from "react-router-dom";
import {PAGINATION_DEFAULT} from "../../../store/constants";
import KeenPagination from "../../components/Pagination";
import {numberFormat} from "../../../util";
import NoAvatar from "../../../assets/images/noavatar.jpg";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Follower = (props) => {
    const dispatch = useDispatch();
    const {followers, followings} = useSelector(state => {
        return {
            followers: state.followReducer.followers,
            followings: state.followReducer.followings,
        }
    });


    let query = useQuery();
    let [currentPage, setCurrentPage] = useState(Number(query.get("page")) || 1);
    let [perPage, setPerPage] = useState(query.get("per_page") || PAGINATION_DEFAULT.ITEM_PER_PAGE);
    useEffect(() => {
        setCurrentPage(Number(query.get("page")) || 1);
        setPerPage(query.get("per_page") || PAGINATION_DEFAULT.ITEM_PER_PAGE);
    }, [query]);


    useEffect(() => {
        if (followings.length === 0) {
            dispatch(getFollowings());
        }
        dispatch(getFollowers());
    }, []);

    return (
        <>
            <div className="card card-custom">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center m-3">
                        <h3 className="card-label font-weight-bolder text-dark m-0"
                            style={{fontSize: "1.275rem"}}>Followers List</h3>
                    </div>
                </div>

                <div className="card-body p-3 table-responsive">
                    <div
                        className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded"
                        id="kt_datatable">
                        <table className="table">
                            <thead>
                            <tr className="text-nowrap">
                                <td>Name</td>
                                <td>Avatar</td>
                                <td>Total Stream</td>
                                <td>Total Follower</td>
                                <th data-field="Action"><span>Action</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            {followers.slice((currentPage - 1) * perPage, currentPage * perPage).map((follower) =>
                                <tr key={follower.UserId}>
                                    <td>{follower.Nickname}</td>
                                    <td>
                                        <div className="symbol symbol-circle">
                                            <img className="of-cv w-50px h-50px" src={follower.Avatar||NoAvatar} alt=""
                                                 onError={(e) => {
                                                     e.target.src = NoAvatar
                                                 }}
                                            />
                                        </div>
                                    </td>
                                    <td>{numberFormat(follower.TotalStream)}</td>
                                    <td>{numberFormat(follower.TotalFollower)}</td>
                                    <td>{
                                        followings.find(following => following.UserId == follower.UserId && !following.DeletedAt) ?
                                            <button className="btn btn-circle text-danger" onClick={() => {
                                                dispatch(unfollow(follower.UserId));
                                            }} title={"unfollow"}>
                                                <i className="far fa-user-minus p-0 m-0 text-reset"/>
                                            </button> :
                                            <button className="btn btn-circle text-primary" onClick={() => {
                                                dispatch(follow(follower.UserId));
                                            }} title={"follow"}>
                                                <i className="far fa-user-plus p-0 m-0 text-reset"/>
                                            </button>
                                    }</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="px-3 py-2">
                    <KeenPagination totalItems={followings.length}/>
                </div>
            </div>
        </>
    )
}

export default Follower;

