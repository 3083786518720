import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {Col, Dropdown} from "react-bootstrap";
import ChannelDropdownItem from "./ChannelDropdownItem";
import CountryDropdownItem from "./CountryDropdownItem";
import moment from "moment";

const ChannelDropdown = (props) => {

    let streamStatistics = props.statisticsReducer.StreamStatistics;
    let checkedCountryCodes = props.checkedCountryCodes;
    let [checkedSongIds, setCheckedSongIds] = useState([]);
    let [selectedChannels, setSelectedChannels] = useState("All Channels");
    let [allChannelsText, setAllChannelsText] = useState("All Channels")

    // Set danh sach channel da chon
    useEffect(() => {
        let checks = [];
        // Set danh sach channel check
        try {
            (props.statisticsReducer.Songs||[]).map((song) => {
                checks.push(song.Id)
            })
        } catch (e) {
            console.log(e);
        }
        setCheckedSongIds(checks);
    }, [props.statisticsReducer.Songs])

    // Set ten channel da chon
    useEffect(() => {
        let checkText = "";
        if (checkedSongIds.length === 1) {
            try {
                (props.statisticsReducer.Songs||[]).forEach(song => {
                    if (song.Id == checkedSongIds[0]) {
                        checkText = `${song.Name}`;
                    }
                });
            } catch (e) {
                console.log(e);
            }
        } else if (checkedSongIds.length < (props.statisticsReducer.Songs||[]).length) {
            checkText = `${checkedSongIds.length} Channels`
        } else {
            checkText = `All Channels (${checkedSongIds.length})`;
        }
        setSelectedChannels(checkText);
        // Set text all channels
        if (checkedSongIds.length > 0) {
            setAllChannelsText("None Channels (0)");
        } else {
            setAllChannelsText(`All Channels (${countAllSongByCountry()})`);
        }
        // Goi callback
        props.onCheckSongs(checkedSongIds);
    }, [checkedSongIds, checkedCountryCodes])

    // Dem tat ca so luong song khi chon country
    let countAllSongByCountry = () => {
        let counts = 0;
        // Tinh theo country da chon
        try {
            (props.statisticsReducer.Songs||[]).map(song => {
                counts += countSongByCountry(song.Id);
            });
        } catch (e) {
            console.log(e);
        }
        return counts;
    }

    // Dem so luong song khi chon country
    let countSongByCountry = (si) => {
        let count = 0;
        // Tinh theo country da chon
        (Object.keys(streamStatistics)||[]).map(date => {
            (Object.keys(streamStatistics[date])||[]).map(songId => {
                (Object.keys(streamStatistics[date][songId])||[]).map(countryCode => {
                    if (songId == si && checkedCountryCodes.includes(countryCode)) {
                        count += Number(streamStatistics[date][songId][countryCode]);
                    }
                });
            });
        });
        return count;
    }

    // Chon 1 channel
    let checkSong = (songId) => {
        if (props.statisticsReducer.Songs != null && checkedSongIds != null) {
            let sc = checkedSongIds.slice(0);
            if (sc.includes(songId)) {
                sc.splice(sc.indexOf(songId), 1);
            } else {
                sc.push(songId);
            }
            setCheckedSongIds(sc);
        }
    }

    // Chon tat ca channel
    let checkAllChannels = (e) => {
        e.preventDefault();
        let isCheckAll = true;
        if (checkedSongIds.length > 0) {
            isCheckAll = false;
        }
        if (isCheckAll) {
            // Chon tat ca channel
            let checks = [];
            // Set danh sach channel check
            try {
                (props.statisticsReducer.Songs||[]).map((song) => {
                    checks.push(song.Id)
                })
            } catch (e) {
                console.log(e);
            }
            setCheckedSongIds(checks);
        } else {
            // Khong chon channel nao
            setCheckedSongIds([]);
        }
    }

    // Kiem tra xem co chon channel nay khong
    let isSongChecked = (songId) => {
        if (checkedSongIds != null) {
            return checkedSongIds.includes(songId);
        } else {
            return false;
        }
    }

    let channelDropdownItems = () => {
        let channelDropdownResult = [];
        // Dem so luong cua moi channel
        try {
            (props.statisticsReducer.Songs||[]).forEach(song => {
                let count = countSongByCountry(song.Id);
                let channelDropdown = {};
                channelDropdown.count = count;
                channelDropdown.item = <ChannelDropdownItem key={`${song.Id} ${isSongChecked(song.Id)}`}
                    name={song.Name} value={song.Id} checked={isSongChecked(song.Id)} count={count} onChecked={(songId) => checkSong(songId)}/>;
                channelDropdownResult.push(channelDropdown);
            });
        } catch (e) {
            console.log(e);
        }
        // Sap xep theo luot view
        channelDropdownResult = channelDropdownResult.slice(0).sort((b, a) => {
            let columnA = a["count"];
            let columnB = b["count"];
            if (columnA === columnB) {
                return 0
            } else if (columnA < columnB) {
                return -1
            } else {
                return 1
            }
        })
        return channelDropdownResult.map((channelDropdown) => channelDropdown.item);
    }

    return <Col className="form-group">
        <label className="col-form-label font-weight-bold pl-0">Channel</label>
        <div className="">
            <Dropdown>
                <Dropdown.Toggle variant="outline-secondary d-flex justify-content-between align-items-center" id="language-dropdown" className="form-control">
                    {selectedChannels}
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="w-100 overflow-x-hidden-y-auto max-h-300px p-0">
                    <div className="btn btn-hover-light p-3 align-items-center d-flex"
                         onClick={(e)=>{checkAllChannels(e)}}>{allChannelsText}</div>
                    {channelDropdownItems()}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    </Col>
}

const mapStateToProps = (state) => {
    return {
        statisticsReducer:state.statisticsReducer,
    };
};

export default connect(mapStateToProps)(ChannelDropdown);
