import React, { Component, useState, useEffect, createRef } from 'react';
import {
    withRouter, Link
} from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { UserChannelAction } from "../../store/constants";
let KTLayoutHeaderMobile = require("../../theme/plugins/header/ktheader-mobile")
let KTLayoutHeaderTopbar = require("../../theme/plugins/header/ktheader-topbar")
import NoAvatar from '../../assets/images/noavatar.jpg';

const AppHeader = (props) => {

    let inputRef = createRef();

    useEffect(() => {
        // Init Mobile Header
        if (typeof KTLayoutHeaderMobile !== 'undefined') {
            KTLayoutHeaderMobile.init('kt_header_mobile');
        }

        // Init Header Topbar For Mobile Mode
        if (typeof KTLayoutHeaderTopbar !== 'undefined') {
            KTLayoutHeaderTopbar.init('kt_header_mobile_topbar_toggle');
        }
    });

    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState(props.searchText);

    const onSubmit = (event) => {
        event.preventDefault();
        inputRef.current.blur();
        if (searchText !== props.searchText) {
            if (props.onSearch) {
                props.onSearch(searchText);
            }
        }
    }

    return (
        <div id="kt_header" className="header">
            <div className="container-fluid px-0 px-lg-6">
                <div className="bg-white d-flex justify-content-between w-100 app-header-container align-items-center p-3">
                    {props.canSearch&&<form className="w-100 w-md-300px m-1" onSubmit={onSubmit}>
                        <div className="input-group">
                            {/*Nhap text search*/}
                            <input ref={inputRef} id="searchInput"
                               name="searchInput" type="text"
                               className="form-control px-10"
                               placeholder="Search Code, Name..."
                               value={searchText}
                               onChange={(e) => {
                                   setSearchText(e.target.value)
                               }}
                               style={{borderTopRightRadius: "4px", borderBottomRightRadius: "4px"}}/>
                            {/*Nut search*/}
                            <label htmlFor="search">
                                <i className="fas fa-search position-absolute"
                                   style={{top: "50%", left: "8px", zIndex: 9}}/>
                            </label>
                            {/*Nut xoa text search*/}
                            {searchText !== "" && (
                                <button type="button" className="btn position-absolute"
                                        onClick={() => {
                                            setSearchText("");
                                            if (props.onSearch) {
                                                props.onSearch("");
                                            }
                                        }}
                                        style={{right: "0px", zIndex: 9}}>
                                    <i className="fas fa-times"/>
                                </button>
                            )}
                        </div>
                    </form>}
                    <h3 className="card-label font-weight-bolder text-dark mb-0"
                         style={{fontSize: "1.275rem"}}>{props.headerText}</h3>
                    <div className="justify-content-end align-items-center d-none d-md-flex">
                        {props.accountReducer.isAuthenticated? <>
                                <h3 className="card-label font-weight-bolder text-dark m-2"
                                    style={{textAlign:"end", fontSize: "1.275rem"}}>Welcome,</h3>
                                <h3 className="card-label text-dark m-0 mr-3"
                                    style={{textAlign:"end", fontSize: "1.275rem"}}>{props.accountReducer.User?.Email}</h3>
                            </>:
                            <h3 className="card-label font-weight-bolder text-dark m-3">IPTV Player</h3>}
                        {props.accountReducer.isAuthenticated ?
                            <Link to={{
                                pathname: "/user",
                            }}>
                                <div className="symbol symbol-circle">
                                    <img alt="Pic" src={`${props.accountReducer.User?.Avatar ? props.accountReducer.User.Avatar : NoAvatar}`}
                                        onError={(e) => e.target.src = NoAvatar}
                                        style={{objectFit:'cover'}}/>
                                </div>
                            </Link> : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        accountReducer: state.accountReducer,
    };
};

export default connect(mapStateToProps)(withRouter(AppHeader));
