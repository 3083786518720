import Swal from "sweetalert2";

export const isTesting = false;

export function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
}

export function sort(l, sortedColumn, descending) {
    if (descending) {
        return l?.slice(0).sort((b, a) => {
            let columnA = Number(a[sortedColumn]) || a[sortedColumn].toLowerCase();
            let columnB = Number(b[sortedColumn]) || b[sortedColumn].toLowerCase();
            if (columnA === columnB) {
                return 0
            } else if (columnA < columnB) {
                return -1
            } else {
                return 1
            }
        })
    } else {
        return l?.slice(0).sort((a, b) => {
            let columnA = Number(a[sortedColumn]) || a[sortedColumn].toLowerCase();
            let columnB = Number(b[sortedColumn]) || b[sortedColumn].toLowerCase();
            if (columnA === columnB) {
                return 0
            } else if (columnA < columnB) {
                return -1
            } else {
                return 1
            }
        })
    }
}

export const ActionType = {
    RESULT_SUCCESS: "Success",
    RESULT_FAILED: "Failed",
    RESULT_0: 0,
    RESULT_1: 1,
    ACCOUNT_EXIST: "ACCOUNT_EXIST",
    NOT_FOUND: "NOT_FOUND",
    FOUND_MANY: "FOUND_MANY",
    SERVER_ERROR: "SERVER_ERROR",
    LOGIN: "LOGIN",
    SIGNUP: "SIGNUP",
    SEND_EMAIL_T0_CHANGE_PASSWORD: "SEND_EMAIL_T0_CHANGE_PASSWORD",
    SUBMIT_CHANGE_PASSWORD: "SUBMIT_CHANGE_PASSWORD",
    AUTO_LOGIN: "AUTO_LOGIN",
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    NOT_AUTHENTICATED: "NOT_AUTHENTICATED",
    FORM_VALIDATION: "FORM_VALIDATION",
};

export const AccountAction = {
    ENABLE_STREAM_COMMENT: "ENABLE_STREAM_COMMENT"
}

export const PagingDisplay = {
    DISPLAY_LIMIT_20: 20,
    DISPLAY_LIMIT_50: 50,
    DISPLAY_LIMIT_100: 100,
};

export const UserChannelAction = {
    LOADING: "LOADING_CHANNEL_LIST",
    GET_USER_CHANNELS: "GET_USER_CHANNELS",
    CHANGE_DISPLAY_LIMIT: "CHANGE_CHANNEL_DISPLAY_LIMIT",
    CHANGE_CURRENT_INDEX: "CHANGE_CHANNEL_CURRENT_INDEX",
    SEARCH_USER_CHANNEL: "SEARCH_USER_CHANNEL",
};

export const GroupAction = {
    GET_GROUPS: "GET_GROUPS",
};

export const StatisticsAction = {
    GET_STREAM_STATISTICS: "GET_STATISTICS_STREAM",
};

export const TransactionAction = {
    GET_TRANSACTIONS: "GET_TRANSACTIONS",
};

export const MessageAction = {
    GET_MESSAGES: "GET_MESSAGES",
    SEND_MESSAGE_RESULT: "SEND_MESSAGE_RESULT",
};

export const SearchStreamAction = {
    LOADING: "SEARCH_STREAM_LOADING",
    SEARCH_STREAM: "SEARCH_STREAM",
    CHANGE_DISPLAY_LIMIT: "SEARCH_STREAM_CHANGE_DISPLAY_LIMIT",
    CHANGE_CURRENT_INDEX: "SEARCH_STREAM_CHANGE_CURRENT_INDEX",
    GET_SEARCH_STREAM: "GET_SEARCH_STREAM",
};

export const PersonalAction = {
    GET_USER_INFO: "GET_USER_INFO",
    UPDATE_USER_INFO: "UPDATE_USER_INFO",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    UPLOAD_AVATAR: "UPLOAD_AVATAR",
};

export const FollowAction = {
    GET_FOLLOWINGS: "GET_FOLLOWINGS",
    GET_FOLLOWERS: "GET_FOLLOWERS",
    FOLLOW: "FOLLOW",
    UNFOLLOW: "UNFOLLOW",
};

export const TopEndToast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
});

export const ReLoginToToast = (message) => {
    TopEndToast.fire({
        icon: "info",
        title: `Re login to ${message}`
    });
};

export const toBase64 = (value) => {
    let buff = new Buffer(value);
    let base64 = buff.toString("base64");
    base64 = base64.replaceAll("+", "-")
    base64 = base64.replaceAll("/", "_")
    base64 = base64.replaceAll("=", ".")
    return base64;
}

export const PAGINATION_DEFAULT = {
    ITEM_PER_PAGE: 20
}
