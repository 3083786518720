import {connect} from "react-redux";
import React, {useState} from "react";

const ChannelDropdownItem = (props) => {

    let [checked, setChecked] = useState(props.checked);
    let name = props.name, counts = props.count;

    return <div className="btn btn-hover-light p-3 align-items-center d-flex"
        onClick={(e)=>{
            setChecked(!checked);
            props.onChecked(props.value);
        }}>
        <input type="checkbox" name={`check${name}`}
            checked={checked} onChange={(e)=>{
            setChecked(!checked);
            props.onChecked(props.value);
        }} />
        <span className="mr-2"/>
        {name} ({counts})</div>
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(ChannelDropdownItem);
