import React, {useState} from 'react'
import {connect, useDispatch} from "react-redux";
import ApiService from "../../services/apiService";
import {ActionType, TopEndToast} from "../../store/constants";
import Swal from "sweetalert2";
import {Link, useHistory} from "react-router-dom";
import {TokenKey, TokenService} from "../../services/storageService";
import group from "../../assets/images/group.png"
import {Button, Form, Modal} from "react-bootstrap";

const StreamGroup = (props) => {
    let history = useHistory();
    let groupId = props.data.GroupId;

    // An hien modal sua group
    const focusEditGroupName = React.createRef();
    const [show, setShow] = useState(false);
    const hideModal = (e) => setShow(false);
    const showModal = (e) => setShow(true);
    const [editGroupName, setEditGroupName] = useState(props.data.GroupName);
    const [editGroupDescription, setEditGroupDescription] = useState(props.data.Description);

    // Xoa group
    const deleteGroup = async () => {
        // Hien thi thong bao co muon xoa group khong
        Swal.fire({
            heightAuto: false,
            title: `Do you want to delete group "${props.data.GroupName}"?`,
            icon: "warning",
            showConfirmButton: true,
            confirmButtonColor: "#dd6b55",
            showCancelButton: true,
            confirmButtonText: `Delete`,
            cancelButtonText: `Cancel`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Dong y xoa
                const postData = {
                    params: {
                        Action: "DeleteGroup",
                        UserId: TokenService.getToken(TokenKey.USER_ID),
                        Token: TokenService.getToken(),
                        GroupId: groupId
                    }
                };
                const {data} = await ApiService.getByUrl('/stream_v2/group.php', postData);
                if (data.Result === ActionType.RESULT_1) {
                    // Hien thi thong bao xoa group thanh cong
                    TopEndToast.fire({
                        icon: 'success',
                        title: `Delete group "${props.data.GroupName}" success!`
                    });
                    // Lay lai danh sach group
                    props.getGroups()
                } else {
                    // Xoa group that bai
                    TopEndToast.fire({
                        icon: 'error',
                        title: `Delete group error!`
                    });
                }
            }
        })
    }

    // Sua group
    const submitEditGroup = async (event) => {
        event.preventDefault();
        // Sua group
        let postData = {
            params: {
                Action: "UpdateGroup",
                UserId: TokenService.getToken(TokenKey.USER_ID),
                Token: TokenService.getToken(),
                GroupId: groupId,
                Name: editGroupName,
                Description: editGroupDescription
            }
        }
        const {data} = await ApiService.getByUrl('/stream_v2/group.php', postData);
        if (data.Result === ActionType.RESULT_1) {
            // An modal di
            setShow(false);
            // Thong bao thanh cong
            TopEndToast.fire({
                icon: 'success',
                title: `Edit group ${editGroupName} success!`
            });
            // Lay danh sach group
            await props.getGroups()
        } else {
            // Thong bao that bai
            TopEndToast.fire({
                icon: 'error',
                title: `Edit group ${editGroupName} error!`
            });
        }
    }

    // Modal sua group
    let editGroupModal =
        <Modal centered show={show} onHide={hideModal} onShow={()=>{focusEditGroupName.current.focus()}}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Group</Modal.Title>
            </Modal.Header>
            <form onSubmit={submitEditGroup}>
                <Modal.Body>
                    <Form.Group controlId="formGroupName">
                        <Form.Label>Name *</Form.Label>
                        <input ref={focusEditGroupName} className="form-control" value={editGroupName}
                               onChange={(e)=>setEditGroupName(e.target.value)}
                               type="text" placeholder="Enter group name" required/>
                    </Form.Group>
                    <Form.Group controlId="formGroupLink">
                        <Form.Label>Description</Form.Label>
                        <input className="form-control" type="text" value={editGroupDescription}
                               onChange={(e)=>setEditGroupDescription(e.target.value)}
                               placeholder="Enter group link"/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="secondary" onClick={hideModal}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary">
                        Save
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>

    return (
        <>
            <tr data-row={props.index + 1}>
                <td data-field="Code" className="align-middle">
                    <span>
                        <img className="w-35px h-auto" src={group} alt="Group"/>
                    </span>
                </td>
                <td colSpan="5" data-field="Name" className="align-middle text-break">
                    <Link to={{
                        pathname: `/group/${props.data.GroupId}`
                    }}>
                        <div className="text-primary font-size-lg mb-0">{props.data.GroupName} ({props.data.Streams?.length??0})</div>
                    </Link>
                </td>
                <td data-field="Action" className="align-middle" style={{whiteSpace: "nowrap"}}>
                    <span onClick={showModal}
                        className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                        title="Edit">
                        <i className="far fa-edit"/>
                    </span>
                    {editGroupModal}
                    <span onClick={(e)=>deleteGroup()}
                          className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                          title="Delete">
                        <i className="far fa-trash-alt"/>
                    </span>
                </td>
            </tr>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userChannelReducer:state.userChannelReducer,
        accountReducer:state.accountReducer,
    };
};

export default connect(mapStateToProps)(StreamGroup);
