import React from 'react';

import Home from "./view/pages/home/Home";
import MyStreams from "./view/pages/my_streams/MyStreams";
import GroupInfo from "./view/pages/my_streams/group/GroupInfo";
import MessageInfo from "./view/pages/message/info/MessageInfo";
import Statistics from "./view/pages/statistics/Statistics";
import Country from "./view/pages/country/Country";
import Messages from "./view/pages/message/Message";
import EditStream from "./view/pages/my_streams/edit/Edit";
import CreateStream from "./view/pages/my_streams/create/Create";
import Search from "./view/pages/search/Search";
import Follower from "./view/pages/profile/Follower";
import Setting from "./view/pages/profile/Setting";
import Following from "./view/pages/profile/Following";
import TransactionHistory from "./view/pages/profile/TransactionHistory";

const routes = [
    {path: '/', exact: true, name: 'Home', component: Home},
    {path: '/stream', exact: true, name: 'MyStream', component: MyStreams},
    {path: '/stream/create', exact: true, name: 'Create Stream', component: CreateStream},
    {path: '/stream/:id', exact: true, name: 'Edit Stream', component: EditStream},
    {path: '/group/:id', exact: true, name: 'Stream Group', component: GroupInfo},
    {path: '/messages', exact: true, name: 'Message', component: Messages},
    {path: '/country', exact: true, name: 'Message', component: Country},
    {path: '/messages/:id', exact: true, name: 'Message Info', component: MessageInfo},
    {path: '/profile/transaction-history', exact: true, name: 'TransactionHistory', component: TransactionHistory},
    {path: '/profile/followings', exact: true, name: 'Following', component: Following},
    {path: '/profile/followers', exact: true, name: 'Follower', component: Follower},
    {path: '/profile/setting', exact: true, name: 'Follower', component: Setting},
    {path: '/search', exact: true, name: 'Search Stream', component: Search},
    {path: '/statistics', exact: true, name: 'Stream Statistics', component: Statistics},
];

export default routes;
