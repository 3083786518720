import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from "react-redux";
import {TokenKey, TokenService} from "../../services/storageService";

const Message = (props) => {

    let additionalParentClass1 = " justify-content-end "
    if (props.data?.ToUser?.UserId == TokenService.getToken(TokenKey.USER_ID)) {
        additionalParentClass1 = " justify-content-start "
    }

    let additionalParentClass2 = " ml-10 bg-primary "
    if (props.data?.ToUser?.UserId == TokenService.getToken(TokenKey.USER_ID)) {
        additionalParentClass2 = " mr-10 "
    }

    let additionalTextClass = " text-white "
    if (props.data?.ToUser?.UserId == TokenService.getToken(TokenKey.USER_ID)) {
        additionalTextClass = " text-secondary "
    }

    return (
        <div className="col-12 d-flex justify-content-center">
            <div className={`col-12 col-md-9 col-xl-6 d-flex ${additionalParentClass1}`}>
                <div className={`card card-custom gutter-b card-stretch p-3 d-inline-flex ${additionalParentClass2}`}>
                    <div className="d-flex align-items-center">
                        {/*Noi dung tin nhan*/}
                        <span className={`font-weight-bold text-break ${additionalTextClass}`}>{props.data.Message}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userChannelReducer:state.userChannelReducer,
        accountReducer:state.accountReducer
    };
};

export default connect(mapStateToProps) (Message);
