import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import Handle from "../../assets/icons/ic_menu_handle.svg";
import Logo from "../../assets/images/mdc_logo.png";
import SidebarItem from "./SidebarItem";
import {logout} from "../../store/actions/authActions";

let KTLayoutAside = require("../../theme/plugins/aside/aside");
let KTLayoutAsideMenu = require("../../theme/plugins/aside/aside-menu");
let KTLayoutAsideToggle = require("../../theme/plugins/aside/aside-toggle");

const mapStateToProps = (state) => {
    return {accountReducer: state.accountReducer};
};

const mapDispatchToProps = {
    logout,
};

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
    }

    render() {
        let sideBarItems = [
            {
                title: "Search Stream",
                icon: "fa-search",
                link: "/search",
                activeLinkId: "/search"
            },
            {
                title: "My Streams",
                icon: "fa-home",
                link: "/stream",
                exactActiveLinkId: "/stream",
            },
            {
                title: "User Profile",
                icon: "fa-user",
                link: "/profile",
                activeLinkId: "/profile",
                dropdowns: [
                    {
                        title: "Transaction History",
                        link: "/profile/transaction-history",
                        activeLinkId: "/profile/transaction-history"
                    },
                    {
                        title: "Followers",
                        link: "/profile/followers",
                        activeLinkId: "/profile/followers"
                    },
                    {
                        title: "Followings",
                        link: "/profile/followings",
                        activeLinkId: "/profile/followings"
                    },
                    {
                        title: "Settings",
                        link: "/profile/setting",
                        activeLinkId: "/profile/setting"
                    },
                ],
            },
            {
                title: "Messages",
                icon: "fa-envelope",
                link: "/messages",
                activeLinkId: "/messages"
            },
            {
                title: "Streams Statistics",
                icon: "fa-analytics",
                link: "/statistics",
                activeLinkId: "/statistics"
            },
            {
                title: "Buy Coin",
                icon: "fa-gem",
                link: "/country",
                activeLinkId: "/country"
            }
        ];

        for (const sidebarItem of sideBarItems) {
            sidebarItem.active = sidebarItem.link === this.props.location.pathname;
            if (sidebarItem.activeLinkId) {
                sidebarItem.active = this.props.location.pathname.includes(sidebarItem.activeLinkId) || this.props.location.pathname.includes(sidebarItem.link);
            }

            if (sidebarItem.hasOwnProperty("exactActiveLinkId") && sidebarItem.exactActiveLinkId === this.props.location.pathname) {
                sidebarItem.active = true;
            }

            if (sidebarItem.dropdowns) {
                for (const dropdownItem of sidebarItem.dropdowns) {
                    dropdownItem.active = dropdownItem.link === this.props.location.pathname;
                    if (dropdownItem.activeLinkId) {
                        dropdownItem.active = this.props.location.pathname.includes(dropdownItem.activeLinkId);
                    }

                    if (dropdownItem.hasOwnProperty("exactActiveLinkId") && dropdownItem.exactActiveLinkId === this.props.location.pathname) {
                        dropdownItem.active = true;
                    }
                }
            }
        }

        return (
            <div className="aside aside-left aside-fixed" id="kt_aside">
                <div className="aside-brand h-80px px-7 flex-shrink-0">
                    {/*<Link className="aside-logo"*/}
                      {/*    to={{*/}
                      {/*        pathname: "/stream",*/}
                      {/*    }}>*/}
                    {/*    <img alt="Logo" src={Logo} className="max-h-40px"/>*/}
                    {/*</Link>*/}
                    <h1 className="aside-logo text-white m-0">Live Player</h1>
                    <button
                        className="aside-toggle btn btn-sm btn-iconSid-white px-0"
                        id="kt_aside_toggle">
                        <span className="svg-icon svg-icon svg-icon-xl">
                            <img src={Handle} alt=""/>
                        </span>
                    </button>
                </div>

                <div
                    id="kt_aside_menu"
                    className="aside-menu my-5"
                    data-menu-vertical="1"
                    data-menu-scroll="1"
                    data-menu-dropdown-timeout="500">
                    <ul className="menu-nav">
                        {sideBarItems.map((item, index) => {
                            return <SidebarItem key={index} item={item}/>;
                        })}
                        {this.props.accountReducer.isAuthenticated ? (
                            <li
                                className="menu-item"
                                aria-haspopup="true"
                                onClick={this.logout}>
                                <Link className="menu-link"
                                      to={{
                                          pathname: "./login",
                                      }}>
                                    <span className="svg-icon menu-icon">
                                        <i className="fa fa-sign-out text-white"/>
                                    </span>
                                    <span className="menu-text">Logout</span>
                                </Link>
                            </li>
                        ) : (
                            <li
                                className="menu-item"
                                aria-haspopup="true">
                                <Link className="menu-link"
                                      to={{
                                          pathname: "./login",
                                      }}>
                                <span className="svg-icon menu-icon">
                                    <i className="fa fa-sign-in text-white"/>
                                </span>
                                    <span className="menu-text">Login</span>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        );
    }

    componentDidMount() {
        // Init Aside Menu
        if (typeof KTLayoutAside !== "undefined") {
            KTLayoutAside.init("kt_aside");
        }

        // Init Aside Toggle
        if (typeof KTLayoutAsideToggle !== "undefined") {
            KTLayoutAsideToggle.init("kt_aside_toggle");
        }

        // Init Aside Menu
        if (typeof KTLayoutAsideMenu !== "undefined") {
            KTLayoutAsideMenu.init("kt_aside_menu");
        }
    }

    logout() {
        this.props.logout();
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Sidebar));
