import {GroupAction} from "../constants";

const initialState = {
    groups: [],
};

// Xu ly cac su kien group
export default function groupReducer(state = initialState, action) {
    switch (action.type) {
        case GroupAction.GET_GROUPS: {
            return {
                ...state,
                ...action.payload,
                groups: action.payload,
            }
        }
        default:
            return state;
    }
}
