import React, {Component, useState, useEffect, createRef} from 'react'
import {connect} from "react-redux";
import {Redirect, useHistory} from "react-router-dom";
import {TokenKey, TokenService} from "../../../services/storageService";
import {Button} from "react-bootstrap";

const Country = (props) => {
    let history = useHistory();

    useEffect(() => {
        window.open(`https://edge.mdcgate.com/sales/subscription/buy_coin.php?UserId=${TokenService.getToken(TokenKey.USER_ID)}`);
    }, [])

    return (
        <>
            <Button onClick={(e) => window.open(`https://edge.mdcgate.com/sales/subscription/buy_coin.php?UserId=${TokenService.getToken(TokenKey.USER_ID)}`)}>Get MDC Coin</Button>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps) (Country);
