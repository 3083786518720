import React, {Component} from 'react';
import styled from 'styled-components';

const FooterStyle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #9EA5BD;
  height: 66px;
  min-height: 66px;
`

class Footer extends Component {
    render() {
        return (
            <FooterStyle>
                <div className="Footer d-flex align-items-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">© Copyright 2021 - MDC Media CO., LTD</div>
                        </div>
                    </div>
                </div>
            </FooterStyle>
        );
    }
}

export default Footer;
