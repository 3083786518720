import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {Col, Dropdown} from "react-bootstrap";
import {DateRangePicker} from "react-date-range";
import {addMonths} from "date-fns";
import moment from "moment";

const DateRangeDropdown = (props) => {

    let streamStatistics = props.statisticsReducer.StreamStatistics;
    let checkedSongIds = props.checkedSongIds;
    let checkedCountryCodes = props.checkedCountryCodes;
    let dateRange = props.dateRange;
    let [dateRangeText, setDateRangeText] = useState("");
    let [showDateRangePicker, setShowDateRangePicker] = useState(false);

    // Dem so luong song khi chon date range
    let countSongByDateRange = () => {
        let count = 0;
        // Tinh theo country da chon
        (Object.keys(streamStatistics)||[]).map(date => {
            (Object.keys(streamStatistics[date]) || []).map(songId => {
                if (checkedSongIds.includes(songId)) {
                    (Object.keys(streamStatistics[date][songId]) || []).map(countryCode => {
                        if (checkedCountryCodes.includes(countryCode)) {
                            count += Number(streamStatistics[date][songId][countryCode]);
                        }
                    });
                }
            });
        });
        return count;
    }

    useEffect(() => {
        if (dateRange != null && dateRange.length > 0) {
            let startDate = moment(dateRange[0].startDate).format("DD/MM/YYYY");
            let endDate = moment(dateRange[0].endDate).format("DD/MM/YYYY");
            setDateRangeText(`${startDate} - ${endDate}`)
        }
    }, [dateRange])

    let dateRangeDropdownItems = <div className="align-items-end d-flex flex-column align-items-end">
        <DateRangePicker
            onChange={item => {
                props.setDateRange([item.selection]);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={dateRange}
            direction="horizontal"
        />
        <div className="btn btn-primary m-3 min-w-100px" onClick={(e) => setShowDateRangePicker(false)}>Done</div>
    </div>

    let dateRangeDropdown =
        <div className="position-absolute bg-white right-0 border align-items-end d-flex flex-column" style={{zIndex: 99}}>
            <DateRangePicker
                onChange={item => {
                    props.setDateRange([item.selection]);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={dateRange}
                direction="horizontal"
            />
            <div className="btn btn-primary m-3 min-w-100px" onClick={(e) => setShowDateRangePicker(false)}>Done</div>
        </div>

    return <Col className="form-group">
        <label className="col-form-label font-weight-bold pl-0">Range</label>
        {/*<input className={`form-control`} value={`${dateRangeText} (${countSongByDateRange()})`} type="text"*/}
        {/*    onClick={(e) => setShowDateRangePicker(true)} readOnly/>*/}
        {/*{showDateRangePicker&&dateRangeDropdown}*/}
        <Dropdown show={showDateRangePicker}
            onToggle={(isOpen, e) => {setShowDateRangePicker(isOpen)}}>
            <Dropdown.Toggle variant="outline-secondary d-flex justify-content-between align-items-center"
                 onClick={(e) => setShowDateRangePicker(!showDateRangePicker)}
                 id="language-dropdown" className="form-control">
                {dateRangeText} ({countSongByDateRange()})
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-auto p-0">
                {dateRangeDropdownItems}
            </Dropdown.Menu>
        </Dropdown>
    </Col>
}

const mapStateToProps = (state) => {
    return {
        statisticsReducer:state.statisticsReducer,
    };
};

export default connect(mapStateToProps)(DateRangeDropdown);
