import React, {useEffect} from 'react'
import {connect, useDispatch} from "react-redux";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import ApiService from "../../../services/apiService";
import md5 from "crypto-js/md5";
import {ActionType} from "../../../store/constants";
import {TokenKey, TokenService} from "../../../services/storageService";
import {loginWebsocket} from "../../../services/ws";
import Swal from "sweetalert2";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Home = (props) => {
    let dispatch = useDispatch();
    let history = useHistory();
    let query = useQuery();
    let userId = query.get("UserId");
    let token = query.get("Token");

    const loginByIdAndToken = async () => {
        console.log(`UserId ${userId} is logging`)
        try {
            const { data } = await ApiService.getByUrl(`/stream_v2/login_by_token.php`, {
                params: {
                    "UserId": userId,
                    "Token": token
                }
            });
            if (data.Result === ActionType.RESULT_SUCCESS) {
                console.log(`UserId ${userId} logged in, data: ${JSON.stringify(data)}`);
                // Dang nhap thanh cong thi luu lai tai khoan, mat khau
                TokenService.saveToken(data.User.Email, TokenKey.EMAIL);
                TokenService.saveToken("", TokenKey.PASSWORD);
                // Luu lai token
                TokenService.saveToken(data.User.Token);
                ApiService.setHeader();
                // Dispatch dang nhap thanh cong
                dispatch({ type: ActionType.LOGIN_SUCCESS, payload: data });
                ApiService.initCommonData();
                // Luu lai user id
                TokenService.saveToken(data.User.UserId, TokenKey.USER_ID)
                // Dang nhap websocket
                loginWebsocket(data.User.UserId, data.User.Token)
                // Chuyen sang man hinh home
                history.push("/stream");
            } else {
                console.log(`UserId ${userId} login with token ${token} error: ${data.Reason}`);
                Swal.fire({
                    icon: 'error',
                    title:data.Reason,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (userId && token) {
            // Dang nhap bang user id va token
            loginByIdAndToken();
        } else {
            // Neu khong co user id va token thi chuyen qua man trang chu
            history.push("/stream")
        }
    }, [])
    return null
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps) (Home);
