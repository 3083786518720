import {createStore, compose, applyMiddleware, combineReducers} from 'redux';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
const initialState = {};
const middleware = [thunk];
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {ActionType} from "./constants";

import accountReducer from "./reducers/accountReducer";
import userChannelReducer from "./reducers/userChannelReducer";
import searchStreamReducer from "./reducers/searchStreamReducer"
import messageReducer from "./reducers/messageReducer"
import groupReducer from "./reducers/groupReducer"
import statisticsReducer from "./reducers/statisticsReducer"
import {logout} from "./actions/authActions";
import {TokenService} from "../services/storageService";
import transactionReducer from "./reducers/transactionReducer";
import followReducer from "./reducers/followReducer";

// Ket noi cac reducer
const reducer = combineReducers({
    accountReducer,
    searchStreamReducer,
    userChannelReducer,
    messageReducer,
    groupReducer,
    statisticsReducer,
    transactionReducer,
    followReducer,
});

const rootReducer = (state, action) => {
    if (action.type === ActionType.LOGOUT_SUCCESS) {
        storage.removeItem('persist:root')
        state = undefined;
    }
    return reducer(state, action)
}

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const store = createStore(
    persistReducer(persistConfig, rootReducer),
    initialState,
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
    }) || compose)(
        applyMiddleware(...middleware),
    )
)
export default store;

export const persistor = persistStore(store, {}, function () {
    if (!TokenService.getToken()) {
        store.dispatch(logout(false));
    }
})
