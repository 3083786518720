import React, {Component, useState, useEffect} from 'react'
import {
    ActionType,
    ReLoginToToast, PersonalAction
} from "../../../../store/constants";
import {connect, useDispatch} from "react-redux";
import ApiService from "../../../../services/apiService";
import Swal from "sweetalert2";
import { Dropdown } from "react-bootstrap";
import { TokenService } from '../../../../services/storageService'
import {useHistory, useParams} from "react-router-dom";
let languagesJson = require("../../../../assets/json/languages.json");

const Edit = (props) => {
    const submitForm = React.createRef();
    // Id cua user channel
    let { id } = useParams();
    let languageKeyList = Object.keys(languagesJson);
    let history = useHistory()

    let userId = props.accountReducer.User.UserId;
    // Thong tin user channel hien tai
    let [currentUserChannel, setCurrentUserChannel] = useState(null);
    let [name, setName] = useState("");
    let [nameError, setNameError] = useState(null);
    let [description, setDescription] = useState("");
    let [url, setUrl] = useState("");
    let [urlError, setUrlError] = useState(null);
    let [language, setLanguage] = useState("English");
    let [privacy, setPrivacy] = useState(0);
    let [showUrl, setShowUrl] = useState(true);

    // Lay user channel hien tai
    useEffect(()=>{
        let userChannel = props.userChannelReducer.userChannels.find(c => c.SongId==id);
        if (!userChannel) {
            // Tim trong group
            (props.groupReducer.groups||[]).forEach(group => {
                (group.Streams||[]).forEach(stream => {
                    if (stream.SongId == id) {
                        userChannel = stream;
                    }
                })
            })
        }
        if (userChannel) {
            setCurrentUserChannel(userChannel);
            setName(userChannel.Name);
            setDescription(userChannel.Description);
            setUrl(userChannel.URL);
            setLanguage(userChannel.Language)
            setPrivacy(userChannel.Privacy)
            setShowUrl(userChannel.ShowURL)
        }
    },[]);

    const saveStream = async (e) => {
        e.preventDefault();
        // Validate du lieu
        let isValid = true
        if (!name) {
            setNameError(true)
            isValid = false
        }
        if (!url) {
            setUrlError(true)
            isValid = false
        }
        if (!isValid) {
            return
        }
        let postData = {
            params: {
                StreamId: id,
                Privacy: privacy,
                Name: name,
                Description: description,
                URL: url,
                UserId: userId,
                Token: TokenService.getToken(),
                Language: language,
                ShowURL: showUrl?1:0
            }
        }
        const {data} = await ApiService.getByUrl('/stream_v2/update_streams.php', postData);
        if (data.Result === ActionType.RESULT_SUCCESS) {
            Swal.fire({
                icon: 'success',
                title: 'Save stream success!',
                willClose(popup) {
                    history.push("/stream")
                }
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: data,
            });
        }
    }

    let nameInput = <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold">Name</label>
        <div className="col-lg-9 col-xl-6">
            <input className={`form-control ${nameError&&"is-invalid"}`} type="text"
                placeholder="Stream name"
                onChange={(e) => setName(e.target.value)}
                value={name}
            />
            <div className="invalid-feedback">Please enter stream name</div>
        </div>
    </div>

    let descriptionTextarea = <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold">Description</label>
        <div className="col-lg-9 col-xl-6">
            <textarea className="form-control" id="description" rows="3" value={description}
                placeholder="Stream description"
                onChange={(e) => setDescription(e.target.value)}/>
        </div>
    </div>

    let urlInput = <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold">Url</label>
        <div className="col-lg-9 col-xl-6">
            <input className={`form-control ${urlError&&"is-invalid"}`} type="text"
               placeholder="Stream url"
               onChange={(e) => setUrl(e.target.value)}
               value={url}
            />
            <div className="invalid-feedback">Please enter stream url</div>
        </div>
    </div>

    let languageDropdown = <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold">Language</label>
        <div className="col-lg-9 col-xl-6">
            <Dropdown>
                <Dropdown.Toggle variant="outline-secondary d-flex justify-content-between align-items-center" id="language-dropdown" className="form-control">
                    {language}
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="w-100 overflow-x-hidden-y-auto max-h-300px">
                    {languageKeyList.map((key) =>
                        <Dropdown.Item key={key}
                            onSelect={(eventKey, e) => {setLanguage(languagesJson[key].name);}}>
                            {languagesJson[key].name}</Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    </div>

    let privacyDropdown = <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold">Privacy</label>
        <div className="col-lg-9 col-xl-6">
            <Dropdown>
                <Dropdown.Toggle variant="outline-secondary d-flex justify-content-between align-items-center" id="privacy-dropdown" className="form-control">
                    {privacy===0?"Public":"Private"}
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="w-100">
                    <Dropdown.Item onSelect={(eventKey, e) => {
                        setPrivacy(0);
                    }}>Public</Dropdown.Item>
                    <Dropdown.Item onSelect={(eventKey, e) => {
                        setPrivacy(1);
                    }}>Private</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    </div>

    let showUrlCheckbox = <label className="checkbox checkbox-outline checkbox-outline-2x checkbox-primary">
        <input type="checkbox" name="Show URL"
            checked={showUrl} onChange={() => setShowUrl(!showUrl)} />
        <span className="mr-2"/>
        Show Full URL
    </label>

    // Giao dien tao channel
    return (
        <>
            <div className="card card-custom card-stretch">
                <div className="card-header py-3 offcanvas-mobile">
                    <div className="card-title align-items-start flex-column tab-content justify-content-center">
                        <h3 className="card-label font-weight-bolder text-dark">Save Stream</h3>
                    </div>
                </div>

                <div className="card-body">
                    <form onSubmit={(e) => {
                        saveStream(e);
                    }}>
                        <button ref={submitForm} type="submit" className="d-none"/>
                        {nameInput}
                        {descriptionTextarea}
                        {urlInput}
                        {languageDropdown}
                        {privacyDropdown}
                        {showUrlCheckbox}
                    </form>
                </div>
                <div className="card-footer offcanvas-desktop text-center d-flex justify-content-center align-items-center">
                    <button type="button" className="btn btn-primary font-weight-bolder" onClick={() => {
                        submitForm.current.click()
                    }}>Save Stream
                    </button>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userChannelReducer:state.userChannelReducer,
        accountReducer:state.accountReducer,
        groupReducer:state.groupReducer,
    };
};

export default connect(mapStateToProps) (Edit);
