import moment from "moment";
import {TokenKey, TokenService} from "./storageService";
import store from '../store';
import {MessageAction} from "../store/constants";
export var isLoggedIn = false
export var websocket = new WebSocket("wss://edge.mdcgate.com/ws");
websocket.onopen = function(evt) { onOpen(evt) };
websocket.onclose = function(evt) { onClose(evt) };
websocket.onmessage = function(evt) { onMessage(evt) };
websocket.onerror = function(evt) { onError(evt) };

export const WEBSOCKET_COMMAND = {
    LOGIN: "LOGIN",
    GET_MESSAGES: "GET_MESSAGES",
    SEND_MESSAGE: "SEND_MESSAGE",
    UPDATE_MESSAGE_STATE: "UPDATE_MESSAGE_STATE"
}

export function loginWebsocket(userId = TokenService.getToken(TokenKey.USER_ID), token = TokenService.getToken()) {
    let loginJson = {
        "Command":"LOGIN",
        "Time":moment().format('YYYY-MM-DD hh:mm:ss'),
        "User":
            {
                "UserId":userId,
                "Token":token
            },
        "Data":
            {
                "Platform":"Web",
            },
        "ServerVersion":2
    }
    doSend(JSON.stringify(loginJson))
}

export function getMessageList(userId = TokenService.getToken(TokenKey.USER_ID), token = TokenService.getToken()) {
    let getMessageListJson = {
        "Command":WEBSOCKET_COMMAND.GET_MESSAGES,
        "Time":moment().format('YYYY-MM-DD hh:mm:ss'),
        "User":
            {
                "UserId":userId,
                "Token":token
            },
        "Data":"",
        "ServerVersion":2
    }
    doSend(JSON.stringify(getMessageListJson))
}

/**
 * Gui tin nhan
 {
  "Command": "UPDATE_MESSAGE_STATE",
  "Data": {
    "MessageId": 147168,
    "Tag": "2021-05-14 10:25:05",
    "Time": "2021-05-17 00:34:31",
    "State": 1
  },
  "Time": "2021-05-14 10:25:06"
}
 * @param message
 * @param toUserId
 */
export function sendMessage(message, toUserId) {
    let sendMessageJson = {
        "Command":WEBSOCKET_COMMAND.SEND_MESSAGE,
        "Time":moment().format('YYYY-MM-DD hh:mm:ss'),
        "User":
            {
                "UserId":TokenService.getToken(TokenKey.USER_ID),
                "Token":TokenService.getToken()
            },
        "Data":{
            "Message":message,
            "Tag":moment().format('YYYY-MM-DD hh:mm:ss'),
            "ToUserId":toUserId
        },
        "ServerVersion":2
    }
    doSend(JSON.stringify(sendMessageJson))
}

export function reconnectWebsocket() {
    websocket = new WebSocket("wss://edge.mdcgate.com/ws");
}

function onOpen(evt) {
    showLog("CONNECTED");
    // Auto login
    loginWebsocket()
}

function onClose(evt) {
    showLog("DISCONNECTED");
    isLoggedIn = false
    // Ket noi lai
    reconnectWebsocket()
}

function onMessage(evt) {
    let response = JSON.parse(evt.data)
    if (response.Command === WEBSOCKET_COMMAND.LOGIN) {
        showLog('LOGGED IN');
        isLoggedIn = true
    } else if (response.Command === WEBSOCKET_COMMAND.GET_MESSAGES) {
        // Chia thanh cac conversation
        store.dispatch({type: MessageAction.GET_MESSAGES, payload: messageClassification(response.Data.Messages)})
        showLog(`MESSAGE LIST SIZE: ${response.Data.Messages.length}`);
    } else if (response.Command === WEBSOCKET_COMMAND.UPDATE_MESSAGE_STATE) {
        // Lay lai danh sach tin nhan
        if (response.Data.State == 1) {
            getMessageList()
            showLog(`SEND MESSAGE SUCCESS!`);
            store.dispatch({type: MessageAction.SEND_MESSAGE_RESULT, payload: true})
        } else {
            showLog(`SEND MESSAGE ERROR!`);
            store.dispatch({type: MessageAction.SEND_MESSAGE_RESULT, payload: false})
        }
    } else {
        showLog(evt.data)
    }
}

// Phan loai danh sach message
function messageClassification(messages) {
    // Lay danh sach user id
    let userIds = [];
    (messages||[]).forEach(message => {
        if (!userIds.includes(message.FromUser.UserId) &&
            message.FromUser.UserId != TokenService.getToken(TokenKey.USER_ID)) {
            userIds.push(message.FromUser.UserId)
        }
        if (!userIds.includes(message.ToUser.UserId) &&
            message.ToUser.UserId != TokenService.getToken(TokenKey.USER_ID)) {
            userIds.push(message.ToUser.UserId)
        }
    })
    // Phan loai conversation
    let conversations = [];
    (userIds||[]).forEach(userId => {
        let conversation = {Messages: []};
        (messages||[]).forEach(message => {
            if (userId == message.FromUser.UserId) {
                if (!conversation.ToUser) {
                    conversation.ToUser = message.FromUser
                }
                conversation.Messages.push(message)
            } else if (userId == message.ToUser.UserId) {
                if (!conversation.ToUser) {
                    conversation.ToUser = message.ToUser
                }
                conversation.Messages.push(message)
            }
        })
        conversations.push(conversation)
    })
    return conversations
}

function onError(evt) {
    showLog('ERROR: ' + evt.data);
    isLoggedIn = false
    // Ket noi lai
    reconnectWebsocket()
}

export function doSend(message) {
    waitForSocketConnection(websocket, function () {
        showLog("SENT: " + message);
        websocket.send(message);
    })
}

// Make the function wait until the connection is made...
function waitForSocketConnection(socket, callback){
    setTimeout(
        function () {
            if (socket.readyState === websocket.OPEN) {
                console.log("Connection is made")
                if (callback != null){
                    callback();
                }
            } else {
                console.log("wait for connection...")
                waitForSocketConnection(socket, callback);
            }
        }, 5);
}

function showLog(message) {
    console.log(`WEBSOCKET ${message}`)
}
