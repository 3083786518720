import React, {Component, useState, useEffect} from 'react'
import {connect} from "react-redux";
import {getMessageList} from "../../../services/ws";
import Conversation from "../../components/Conversation";

const Message = (props) => {

    useEffect(() => {
        // Lay danh sach message
        getMessageList()
    }, [])

    // Hien thi danh sach message
    return (
        <>
            <h3 className="card-label font-weight-bolder text-dark" style={{fontSize: "1.275rem"}}>My Messages</h3>
            <div className="channel-row mt-5">
                {props.messageReducer.messageList.map(value => <Conversation key={value?.ToUser?.Nickname} data={value}/>)}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        // Gan reducer vao props
        messageReducer:state.messageReducer,
    };
};

export default connect(mapStateToProps) (Message);
