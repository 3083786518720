import React, {useEffect, useState} from 'react';
import {Form, Row} from "react-bootstrap";
import {connect, useDispatch} from "react-redux";
import ApiService from "../../../services/apiService";
import {AccountAction, ActionType, TransactionAction, UserChannelAction} from "../../../store/constants";
import {TokenKey, TokenService} from "../../../services/storageService";

const Setting = (props) => {

    let dispatch = useDispatch();
    let [enableStreamComment, setEnableStreamComment] = useState(props.accountReducer.User.EnableStreamComment == 1);

    // Thay doi enable stream comment
    let changeEnableStreamComment = async () => {
        setEnableStreamComment(!enableStreamComment);
        // Goi api thay doi enable stream comment
        let enable = enableStreamComment?0:1;
        let postData = {
            params: {
                UserId: TokenService.getToken(TokenKey.USER_ID),
                Token: TokenService.getToken(),
                Enable: enable
            }
        };
        let {data} = await ApiService.getByUrl("https://edge.mdcgate.com/livemedia/stream_v2/toggle_stream_comment.php", postData)
        if (data.Result == ActionType.RESULT_SUCCESS) {
            // Thay doi enable stream comment
            dispatch({type: AccountAction.ENABLE_STREAM_COMMENT, payload: enable})
        }
    }

    // Thay doi giao dien khi enable stream comment thay doi
    useEffect(() => {
        setEnableStreamComment(props.accountReducer.User.EnableStreamComment == 1);
    }, [props.accountReducer.User])

    return (
        <>
            <div className="card card-custom">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center m-3">
                        <h3 className="card-label font-weight-bolder text-dark ml-3 mb-0"
                            style={{fontSize: "1.275rem"}}>Setting</h3>
                    </div>
                </div>

                <div className="card-body p-3 table-responsive">
                        <form className="form">
                            <div className="d-flex flex-row align-items-center">
                                <label htmlFor="enable_stream_comment" className="mx-3 mb-0">Enable Stream Comment</label>
                                <div className="col-3">
                                   <span className="switch switch-outline switch-primary">
                                    <label>
                                     <input type="checkbox" checked={enableStreamComment} id="enable_stream_comment" onChange={event => changeEnableStreamComment()}/>
                                     <span/>
                                    </label>
                                   </span>
                                </div>
                            </div>
                        </form>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    return {
        accountReducer: state.accountReducer,
        transactionReducer: state.transactionReducer,
    };
};

export default connect(mapStateToProps)(Setting);