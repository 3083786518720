import {UserChannelAction, PagingDisplay} from "../constants";

const initialState = {
    searchUserChannel: "",
    currentIndex: 1,
    userChannels: [],
    currentDisplayingLimit: PagingDisplay.DISPLAY_LIMIT_20,
    userChannelsCount: PagingDisplay.DISPLAY_LIMIT_20,
    loading: false
};

// Xu ly cac su kien channel
export default function userChannelReducer(state = initialState, action) {
    switch (action.type) {
        case UserChannelAction.LOADING: {
            return {
                ...state,
                ...action.payload,
                loading: true,
            }
        }
        case UserChannelAction.CHANGE_DISPLAY_LIMIT: {
            return {
                ...state,
                ...action.payload,
                loading: true,
                currentIndex: 1,
                currentDisplayingLimit: action.payload
            }
        }
        case UserChannelAction.CHANGE_CURRENT_INDEX: {
            if (parseInt(action.payload) == 1) {
                return {
                    ...state,
                    ...action.payload,
                    currentIndex: parseInt(action.payload)
                }
            } else {
                return {
                    ...state,
                    ...action.payload,
                    loading: true,
                    currentIndex: parseInt(action.payload)
                }
            }
        }
        case UserChannelAction.GET_USER_CHANNELS: {
            return {
                ...state,
                ...action.payload,
                loading: false,
                userChannelsCount: action.payload.count,
                userChannels: action.payload.userChannels,
            }
        }
        case UserChannelAction.SEARCH_USER_CHANNEL: {
            return {
                ...state,
                searchUserChannel: action.payload
            }
        }
        default:
            return state;
    }
}
