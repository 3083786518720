import React, { useState, useEffect} from 'react'
import {
    ActionType,
    SearchStreamAction,
    PagingDisplay, sort,
} from "../../../store/constants";
import {connect, useDispatch} from "react-redux";
import ApiService from "../../../services/apiService";
import Swal from "sweetalert2";
import {Dropdown} from "react-bootstrap";
import SearchStream from "../../components/SearchStream";
import {Redirect} from "react-router-dom";

const Search = (props) => {

    const dispatch = useDispatch();
    
    const [maxIndex, setMaxIndex] = useState(Math.ceil(props.searchStreamReducer.streamsCount/props.searchStreamReducer.currentDisplayingLimit));
    const [currentIndex, setCurrentIndex] = useState(props.searchStreamReducer.currentIndex);

    const [sortedColumn, setSortedColumn] = useState("SongId");
    const [descending, setDescending] = useState(false);
    const [sortedStreams, setSortedStreams] = useState(props.searchStreamReducer.streams);

    // Lay danh sach cac search stream neu co su thay doi trong trang
    useEffect(() => {
        searchStreams();
    }, [props.searchStreamReducer.currentIndex,
            props.searchStreamReducer.searchStream,
            props.searchStreamReducer.currentDisplayingLimit]);

    // Chuyen trang thi thay doi trang hien tai
    useEffect(() => {
        setCurrentIndex(props.searchStreamReducer.currentIndex);
    }, [props.searchStreamReducer.currentIndex]);

    useEffect(() => {
        // Sap xep danh sach stream
        if (sortedColumn) {
            setSortedStreams(sort(props.searchStreamReducer.streams, sortedColumn, descending));
        } else {
            setSortedStreams(props.searchStreamReducer.streams);
        }
    }, [props.searchStreamReducer.streams]);

    const setSort = (property) => {
        setSortedColumn(property);
        if (sortedColumn === "" || sortedColumn !== property) {
            // Sap xep lan dau tang dan
            setSortedStreams(sort(props.searchStreamReducer.streams, property, true));
            setDescending(true);
        } else {
            // Sap xep nguoc lai
            setSortedStreams(sort(props.searchStreamReducer.streams, property, !descending));
            setDescending(!descending);
        }
    }

    const changeCurrentIndex = (index) => {
        index = parseInt(index);
        if (index < 1) {
            dispatch({type: SearchStreamAction.CHANGE_CURRENT_INDEX, payload: 1})
        } else if (index > maxIndex) {
            dispatch({type: SearchStreamAction.CHANGE_CURRENT_INDEX, payload: maxIndex})
        } else if (index !== currentIndex) {
            dispatch({type: SearchStreamAction.CHANGE_CURRENT_INDEX, payload: index})
        }
    }

    if (!props.searchStreamReducer.currentIndex || props.searchStreamReducer.currentIndex < 1) {
        changeCurrentIndex(1);
    }

    const searchStreams = async () => {
        if (props.searchStreamReducer.searchStream) {
            try {
                // Hien thi loading
                dispatch({
                    type:SearchStreamAction.LOADING,
                    payload:{}
                })
                const postData = {
                    params: {
                        Name: props.searchStreamReducer.searchStream,
                        StartIndex: (props.searchStreamReducer.currentIndex - 1) * props.searchStreamReducer.currentDisplayingLimit,
                        MaxResult: props.searchStreamReducer.currentDisplayingLimit,
                        Type: 1
                    }
                };
                const {data} = await ApiService.getByUrl('/stream_v2/search_streams.php', postData);
                // Luu lai danh sach search stream, so luong trang
                dispatch({
                    type:SearchStreamAction.GET_SEARCH_STREAM,
                    payload:{
                        streams:data.Streams,
                        count:data.TotalStream,
                    }
                });
                // Luu lai so luong trang
                setMaxIndex(Math.ceil(data.TotalStream/props.searchStreamReducer.currentDisplayingLimit))

                if (data.Result === ActionType.RESULT_SUCCESS) {

                } else if (data.Result === ActionType.RESULT_FAILED) {
                    // Khong tim thay stream nao
                    if (data.Reason === ActionType.NOT_FOUND) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Stream not found'
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Server error!'
                        });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            // Khong search gi thi khong co du lieu
            dispatch({
                type:SearchStreamAction.GET_SEARCH_STREAM,
                payload:{
                    streams:[],
                    count:0,
                }
            });
        }
    }

    const onSubmitGoToPage = async (event) => {
        event.preventDefault()
        dispatch({type: SearchStreamAction.CHANGE_CURRENT_INDEX, payload: currentIndex});
    }

    const tableHeader = (header) => <th key={header.Name} className={`${(sortedColumn === header.Sort)?"text-primary":""}`}
        data-field={header.Name} style={{cursor:"pointer"}}
        onClick={(e)=>setSort(header.Sort)}>
        <span className={(sortedColumn===header.Sort)?"text-primary":""}>{header.Name}</span>
        {sortedColumn===header.Sort&&<i className={`text-primary ml-2 far fa-1x ${!descending?"fa-arrow-down":" fa-arrow-up"}`}/>}
    </th>

    const tableHeaders = [
        {Name: "Code", Sort: "Code"},
        {Name: "Name", Sort: "Name"},
        {Name: "URL", Sort: "URL"},
        {Name: "Language", Sort: "Language"},
        {Name: "Views", Sort: "ViewByAll"},
        {Name: "LastOnline", Sort: "LastOnline"}]

    // Hien thi danh sach stream
    return (
        <>
            <div className="card card-custom">
                <div className="card-body p-3 table-responsive">
                    <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded"
                         id="kt_datatable">
                        <table className="table">
                            <thead>
                            <tr className="text-nowrap">
                                {tableHeaders.map(h => tableHeader(h))}
                            </tr>
                            </thead>
                            <tbody>
                            {sortedStreams?.map((stream, i) =>
                                <SearchStream data={stream} key={stream.SongId} index={i}/>)}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="bg-white app-footer-container d-flex px-3
                    justify-content-between align-items-center flex-wrap">
                    <div className="d-flex flex-wrap py-2 mr-3">

                        {/*Hien thi nut ve trang dau neu co nhieu hon 1 trang dang truoc*/}
                        <a href="#" className={"btn btn-icon btn-sm btn-light-primary mr-2 my-1" +
                        (props.searchStreamReducer.currentIndex<=1?" disabled":"")}
                           onClick={()=> {changeCurrentIndex(1)}}>
                            <i className="fas fa-angle-double-left"/></a>
                        {/*Hien thi nut ve trang truoc neu co nhieu hon 1 trang dang truoc*/}
                        <a href="#" className={"btn btn-icon btn-sm btn-light-primary mr-2 my-1" +
                        (props.searchStreamReducer.currentIndex<=1?" disabled":"")}
                           onClick={()=> {changeCurrentIndex(props.searchStreamReducer.currentIndex-1)}}>
                            <i className="fas fa-angle-left"/></a>

                        {props.searchStreamReducer.currentIndex>3&&(
                            // Neu nhieu hon 3 trang thi hien thi dau ... dang truoc
                            <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">...</a>
                        )}
                        {props.searchStreamReducer.currentIndex>2&&(
                            // Neu nhieu hon 2 trang thi hien thi trang thu 2 truoc do
                            <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
                               onClick={()=> {changeCurrentIndex(props.searchStreamReducer.currentIndex-2)}}>
                                {props.searchStreamReducer.currentIndex-2}</a>
                        )}
                        {props.searchStreamReducer.currentIndex>1&&(
                            // Neu nhieu hon 1 trang thi hien thi trang thu 1 truoc do
                            <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
                               onClick={()=> {changeCurrentIndex(props.searchStreamReducer.currentIndex-1)}}>
                                {props.searchStreamReducer.currentIndex-1}</a>
                        )}

                        {/*Trang dang chon*/}
                        <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1"
                           onClick={()=> {changeCurrentIndex(props.searchStreamReducer.currentIndex)}}>
                            {props.searchStreamReducer.currentIndex}</a>

                        {props.searchStreamReducer.currentIndex<maxIndex&&(
                            // Neu con nhieu hon 1 trang thi hien thi trang thu 1 sau do
                            <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
                               onClick={()=> {changeCurrentIndex(props.searchStreamReducer.currentIndex+1)}}>
                                {props.searchStreamReducer.currentIndex+1}</a>
                        )}

                        {props.searchStreamReducer.currentIndex+1<maxIndex&&(
                            // Neu con nhieu hon 2 trang thi hien thi trang thu 2 sau do
                            <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
                               onClick={()=> {changeCurrentIndex(props.searchStreamReducer.currentIndex+2)}}>
                                {props.searchStreamReducer.currentIndex+2}</a>
                        )}

                        {props.searchStreamReducer.currentIndex+2<maxIndex&&(
                            // Neu con nhieu hon 3 trang thi hien thi dau ...
                            <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">...</a>
                        )}

                        {/*Hien thi nut sang trang tiep neu co nhieu hon 1 trang dang sau*/}
                        <a href="#" className={"btn btn-icon btn-sm btn-light-primary mr-2 my-1" +
                        (props.searchStreamReducer.currentIndex>=maxIndex?" disabled":"")}
                           onClick={()=> {changeCurrentIndex(props.searchStreamReducer.currentIndex+1)}}>
                            <i className="fas fa-angle-right"/></a>
                        {/*Hien thi nut sang trang cuoi neu co nhieu hon 1 trang dang sau*/}
                        <a href="#" className={"btn btn-icon btn-sm btn-light-primary mr-2 my-1" +
                        (props.searchStreamReducer.currentIndex>=maxIndex?" disabled":"")}
                           onClick={()=> {changeCurrentIndex(maxIndex)}}>
                            <i className="fas fa-angle-double-right"/></a>
                    </div>
                    <div className="d-flex align-items-center py-3">
                        {props.searchStreamReducer.loading&&(
                            <div className="d-flex align-items-center">
                                <div className="mr-2 text-muted">Loading...</div>
                                <div className="spinner spinner-primary mr-10"/>
                            </div>
                        )}

                        <form className="d-flex align-items-center"
                              onSubmit={onSubmitGoToPage}>
                            <label className="mb-0 mr-2">Go to</label>
                            <input id="currentIndex" type="number" value={currentIndex}
                                   onChange={(e) => {
                                       // Validate current index
                                       if (e.target.value <= 0) {
                                           setCurrentIndex(1);
                                       } else if (e.target.value > maxIndex) {
                                           setCurrentIndex(maxIndex)
                                       } else {
                                           setCurrentIndex(e.target.value)
                                       }
                                   }}
                                   className="form-control mr-4" placeholder="Enter page"
                                   style={{width:"100px"}}/>
                        </form>

                        <Dropdown className="mr-3">
                            <Dropdown.Toggle id="dropdown-basic" className="btn btn-secondary">
                                {props.searchStreamReducer.currentDisplayingLimit}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onSelect={(eventKey, e) => {
                                    dispatch({type: SearchStreamAction.CHANGE_DISPLAY_LIMIT, payload: Number(PagingDisplay.DISPLAY_LIMIT_20)});
                                }}>{PagingDisplay.DISPLAY_LIMIT_20}</Dropdown.Item>
                                <Dropdown.Item onSelect={(eventKey, e) => {
                                    dispatch({type: SearchStreamAction.CHANGE_DISPLAY_LIMIT, payload: Number(PagingDisplay.DISPLAY_LIMIT_50)});
                                }}>{PagingDisplay.DISPLAY_LIMIT_50}</Dropdown.Item>
                                <Dropdown.Item onSelect={(eventKey, e) => {
                                    dispatch({type: SearchStreamAction.CHANGE_DISPLAY_LIMIT, payload: Number(PagingDisplay.DISPLAY_LIMIT_100)});
                                }}>{PagingDisplay.DISPLAY_LIMIT_100}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <span className="text-muted mr-3">{props.searchStreamReducer.streamsCount} streams</span>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        // Gan reducer vao props
        searchStreamReducer:state.searchStreamReducer,
        accountReducer:state.accountReducer,
    };
};

export default connect(mapStateToProps) (Search);
