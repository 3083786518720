import {AccountAction, ActionType} from "../constants";
import {TokenService} from "../../services/storageService";

const initialState = {
    isAuthenticated: !!TokenService.getToken(),
};

// Xu ly cac su kien cua nguoi dung
export default function accountReducer(state = initialState, action) {
    switch (action.type) {
        case ActionType.SIGNUP: {
            return {
                ...state,
                ...action.payload
            }
        }
        case ActionType.LOGIN: {
            return {
                ...state,
                ...action.payload
            }
        }
        case ActionType.LOGIN_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
            }
        }
        case ActionType.LOGOUT_SUCCESS: {
            return {
                ...state,
                isAuthenticated: false,
            }
        }
        case AccountAction.ENABLE_STREAM_COMMENT: {
            return {
                ...state,
                User: {
                    ...state.User,
                    EnableStreamComment: action.payload
                }
            }
        }
        default:
            return state;
    }
}
