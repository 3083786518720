import {connect} from "react-redux";
import React, {useState} from "react";
let countryJson = require("../../assets/json/countries.json");

const CountryDropdownItem = (props) => {

    let [checked, setChecked] = useState(props.checked);
    let name = "", count = props.count;
    countryJson.forEach(country => {
        if (country.code == props.value) {
            name = country.name;
        }
    })

    return <div className="btn btn-hover-light p-3 align-items-center d-flex"
        onClick={(e)=>{
            setChecked(!checked);
            props.onChecked(props.value);
        }}>
        <input type="checkbox" name={`check${props.value}`}
            checked={checked} onChange={(e)=>{
            setChecked(!checked);
            props.onChecked(props.value);
        }} />
        <span className="mr-2"/>
        {name} ({count})</div>
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(CountryDropdownItem);
