import React, {Suspense} from 'react'
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom'

// routes config
import routes from '../../routes'
import NoMatch from "../pages/NoMatch";

const TheContent = () => {
    return (
        <div className="container-fluid">
            <Switch>
                {routes.map((route, idx) => {
                    return route.component && (
                        <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={props => (
                                <route.component {...props} />
                            )}/>
                    )
                })}
                <Route>
                    <NoMatch />
                </Route>
            </Switch>
        </div>
    )
}

export default React.memo(TheContent)
