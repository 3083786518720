import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import Logo from '../../assets/images/mdc_logo.png';
import NoAvatar from '../../assets/images/noavatar.jpg';
import MenuToggle from '../../assets/icons/ic_menu_mobile_toggle.svg';
import {connect} from "react-redux";
let KTLayoutHeaderMobile = require("../../theme/plugins/header/ktheader-mobile")
let KTLayoutHeaderTopbar = require("../../theme/plugins/header/ktheader-topbar")

const mapStateToProps = state => {
    return {
        accountReducer: state.accountReducer.User
    };
};
class MobileHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let userData = this.props.accountReducer;

        return (
            <div id="kt_header_mobile" className="header-mobile">
                <Link to={{
                    pathname: "/stream",
                }}>
                    <img alt="Logo" src={Logo} className="max-h-30px" />
                </Link>

                <div className="d-flex align-items-center">
                    <button className="btn btn-icon btn-icon-white btn-hover-icon-white" id="kt_aside_mobile_toggle">
                        <span className="svg-icon svg-icon-xxl">
                            <img src={MenuToggle} />
                        </span>
                    </button>
                    <Link to={{
                        pathname: "/user",
                    }}>
                        <button className="btn btn-icon btn-icon-white btn-hover-icon-white ml-1"
                                id="kt_header_mobile_topbar_toggle" >
                        <div className="symbol symbol-25">
                            <img alt="Pic" className="rounded-circle symbol-label" src={
                                userData?.Avatar ? (userData.Avatar.includes('http')?userData.Avatar:(userData.Avatar)): NoAvatar
                            } style={{objectFit:'cover'}}
                             onError={(e)=>e.target.src=NoAvatar}/>
                            </div>
                        </button>
                    </Link>
                </div>
            </div>
        );
    }

    componentDidMount() {
        // Init Mobile Header
        if (typeof KTLayoutHeaderMobile !== 'undefined' && !window.KTLayoutHeaderMobileInited) {
            window.KTLayoutHeaderMobileInited = true;
            KTLayoutHeaderMobile.init('kt_header_mobile');
        }

        // Init Header Topbar For Mobile Mode
        if (typeof KTLayoutHeaderTopbar !== 'undefined' && !window.KTLayoutHeaderTopbarInited) {
            window.KTLayoutHeaderTopbarInited = true;
            KTLayoutHeaderTopbar.init('kt_header_mobile_topbar_toggle');
        }
    }
}

export default connect(mapStateToProps)(MobileHeader);
