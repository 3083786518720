import React, {useState, useEffect} from 'react'
import {
    ActionType,
    UserChannelAction,
    PagingDisplay, sort, GroupAction, TopEndToast
} from "../../../store/constants";
import {connect, useDispatch} from "react-redux";
import ApiService from "../../../services/apiService";
import Swal from "sweetalert2";
import UserChannel from "../../components/UserChannel";
import {Button, Dropdown, Form, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {TokenKey, TokenService} from "../../../services/storageService";
import StreamGroup from "../../components/StreamGroup";

/**
    {
        "Description": "",
        "ImageURL": "http://edge.mdcgate.com/livemedia/stream_v2/get_thumbnails.php?code=162962",
        "Avatar": "http://edge.mdcgate.com/livemedia/public/images/useravatar/02f052fbf0fa27f82cc645c44eae5ec9.jpg",
        "Language": "English",
        "Type": "0",
        "SongId": "1060027",
        "Name": "ustv247",
        "UserId": "40",
        "URL": "http://ustv247.tv/msnbclive/ parseUrl=1",
        "Source": "ustv247.tv",
        "LikeCount": "0",
        "ViewByAll": "117",
        "Code": "162962",
        "Privacy": "0",
        "LastOnline": "2021-05-19",
        "UserName": "Chiến Nguyễn",
        "ProfileURL": null,
        "RateCount": "0",
        "AverageRating": "0",
        "ShowURL": "1",
        "CategoryName": "General",
        "CategoryImagePath": "http://edge.mdcgate.com/livemedia/public/icon/general_icon.png"
    }
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MyStreams = (props) => {

    const dispatch = useDispatch();
    
    const [maxIndex, setMaxIndex] = useState(Math.ceil(props.userChannelReducer.userChannelsCount/props.userChannelReducer.currentDisplayingLimit));
    const [currentIndex, setCurrentIndex] = useState(props.userChannelReducer.currentIndex);

    const [sortedColumn, setSortedColumn] = useState("Name");
    const [descending, setDescending] = useState(false);
    const [sortedUserChannels, setSortedUserChannels] = useState(props.userChannelReducer.userChannels);

    // An hien modal them group
    const focusAddNewGroupName = React.createRef();
    const [show, setShow] = useState(false);
    const hideModal = (e) => setShow(false);
    const showModal = (e) => setShow(true);
    const [addNewGroupName, setAddNewGroupName] = useState("");
    const [addNewGroupDescription, setAddNewGroupDescription] = useState("");

    // Lay danh sach cac user channel neu co su thay doi trong trang
    useEffect(() => {
        getUserChannels();
    }, [props.userChannelReducer.currentIndex,
            props.userChannelReducer.currentDisplayingLimit]);

    useEffect(() => {
        let searchText = props.userChannelReducer.searchUserChannel;
        setSortedUserChannels(props.userChannelReducer.userChannels.filter(stream => stream.Name.includes(searchText) || stream.URL.includes(searchText)));
    }, [props.userChannelReducer.searchUserChannel]);

    // Chuyen trang thi thay doi trang hien tai
    useEffect(() => {
        setCurrentIndex(props.userChannelReducer.currentIndex);
    }, [props.userChannelReducer.currentIndex]);

    useEffect(() => {
        // Sap xep danh sach channel
        if (sortedColumn) {
            setSortedUserChannels(sort(props.userChannelReducer.userChannels, sortedColumn, descending));
        } else {
            setSortedUserChannels(props.userChannelReducer.userChannels);
        }
    }, [props.userChannelReducer.userChannels]);

    const setSort = (property) => {
        setSortedColumn(property);
        if (sortedColumn === "" || sortedColumn !== property) {
            // Sap xep lan dau tang dan
            setSortedUserChannels(sort(props.userChannelReducer.userChannels, property, true));
            setDescending(true);
        } else {
            // Sap xep nguoc lai
            setSortedUserChannels(sort(props.userChannelReducer.userChannels, property, !descending));
            setDescending(!descending);
        }
    }

    const changeCurrentIndex = (index) => {
        index = parseInt(index);
        if (index < 1) {
            dispatch({type: UserChannelAction.CHANGE_CURRENT_INDEX, payload: 1})
        } else if (index > maxIndex) {
            dispatch({type: UserChannelAction.CHANGE_CURRENT_INDEX, payload: maxIndex})
        } else if (index !== currentIndex) {
            dispatch({type: UserChannelAction.CHANGE_CURRENT_INDEX, payload: index})
        }
    }

    if (!props.userChannelReducer.currentIndex || props.userChannelReducer.currentIndex < 1) {
        changeCurrentIndex(1);
    }

    // Lay danh sach user channel va group
    const getUserChannels = async () => {
        try {
            dispatch({
                type:UserChannelAction.LOADING,
                payload:{}
            })
            // Lay user channel
            let postData = {
                params: {
                    TargetId: props.accountReducer.User?.UserId,
                    StartIndex: (props.userChannelReducer.currentIndex - 1) * props.userChannelReducer.currentDisplayingLimit,
                    MaxResult: props.userChannelReducer.currentDisplayingLimit
                }
            };
            let userChannelData = await ApiService.getByUrl('/stream_v2/get_streams_of_user_without_group.php', postData);
            // Luu lai danh sach channel, so luong trang
            dispatch({
                type:UserChannelAction.GET_USER_CHANNELS,
                payload:{
                    userChannels:userChannelData.data.UserChannels,
                    count:userChannelData.data.TotalChannel,
                }
            });
            // Luu lai so luong trang
            setMaxIndex(Math.ceil(userChannelData.data.TotalChannel/props.userChannelReducer.currentDisplayingLimit))

            if (userChannelData.data.Result === ActionType.RESULT_FAILED) {
                // Khong tim thay channel nao
                Swal.fire({
                    icon: 'error',
                    title: `Server error ${userChannelData.data.Reason}`
                });
            }
            // Lay danh sach group
            await getGroups()
        } catch (error) {
            console.log(error);
        }
    }

    // Lay danh sach group
    const getGroups = async () => {
        // Lay group
        let postData = {
            params: {
                Action: "Get",
                UserId: TokenService.getToken(TokenKey.USER_ID),
                Token: TokenService.getToken()
            }
        };
        let groupData = await ApiService.getByUrl('/stream_v2/group.php', postData);
        // Luu lai danh sach group
        dispatch({
            type:GroupAction.GET_GROUPS,
            payload:groupData.data.Groups,
        });
    }

    const onSubmitGoToPage = async (event) => {
        event.preventDefault()
        dispatch({type: UserChannelAction.CHANGE_CURRENT_INDEX, payload: currentIndex});
    }

    const tableHeader = (header) => <th key={header.Name} className={`${(sortedColumn === header.Sort)?"text-primary":""}`}
        data-field={header.Name} style={{cursor:"pointer"}}
        onClick={(e)=>setSort(header.Sort)}>
        <span className={(sortedColumn===header.Sort)?"text-primary":""}>{header.Name}</span>
        {sortedColumn===header.Sort&&<i className={`text-primary ml-2 far fa-1x ${!descending?"fa-arrow-down":" fa-arrow-up"}`}/>}
    </th>

    const tableHeaders = [
        {Name: "Code", Sort: "Code"},
        {Name: "Name", Sort: "Name"},
        {Name: "URL", Sort: "URL"},
        {Name: "Language", Sort: "Language"},
        {Name: "Views", Sort: "ViewByAll"},
        {Name: "LastOnline", Sort: "LastOnline"}]

    // Them group
    const submitAddNewGroup = async (event) => {
        event.preventDefault();
        // Them group
        let postData = {
            params: {
                Action: "New",
                UserId: TokenService.getToken(TokenKey.USER_ID),
                Token: TokenService.getToken(),
                Name: addNewGroupName,
                Description: addNewGroupDescription
            }
        }
        const {data} = await ApiService.getByUrl('/stream_v2/group.php', postData);
        if (data.Result === ActionType.RESULT_1) {
            // An modal di
            setShow(false);
            // Xoa du lieu modal
            setAddNewGroupName("");
            setAddNewGroupDescription("");
            // Thong bao thanh cong
            TopEndToast.fire({
                icon: 'success',
                title: `Add group ${addNewGroupName} success!`
            });
            // Lay danh sach group
            await getGroups()
        } else {
            // Thong bao that bai
            TopEndToast.fire({
                icon: 'error',
                title: `Add group ${addNewGroupName} error!`
            });
        }
    }

    // Modal them group
    let addGroupModal =
        <Modal centered show={show} onHide={hideModal} onShow={()=>{focusAddNewGroupName.current.focus()}}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Group</Modal.Title>
            </Modal.Header>
            <form onSubmit={submitAddNewGroup}>
                <Modal.Body>
                    <Form.Group controlId="formGroupName">
                        <Form.Label>Name *</Form.Label>
                        <input ref={focusAddNewGroupName} className="form-control" value={addNewGroupName}
                               onChange={(e)=>setAddNewGroupName(e.target.value)}
                               type="text" placeholder="Enter group name" required/>
                    </Form.Group>
                    <Form.Group controlId="formGroupLink">
                        <Form.Label>Description</Form.Label>
                        <input className="form-control" type="text" value={addNewGroupDescription}
                               onChange={(e)=>setAddNewGroupDescription(e.target.value)}
                               placeholder="Enter group link"/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="secondary" onClick={hideModal}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary">
                        Add
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>

    // Hien thi danh sach channel
    return (
        <>
            <div className="card card-custom">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center m-3">
                        <h3 className="card-label font-weight-bolder text-dark m-0" style={{fontSize: "1.275rem"}}>My Streams</h3>
                    </div>
                    <div className="d-flex align-items-center m-3">
                        <Link to={{ pathname: "/stream/create" }}>
                            <div className="btn btn-primary font-weight-bolder mr-3">
                                Add Stream
                            </div>
                        </Link>

                        <div onClick={showModal} className="btn btn-primary font-weight-bolder">
                            <i className="fal fa-folders"></i>
                            Add Group
                        </div>
                        {addGroupModal}
                    </div>
                </div>

                <div className="card-body p-3 table-responsive">
                    <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded"id="kt_datatable">
                        <table className="table">
                            <thead>
                            <tr className="text-nowrap">
                                {tableHeaders.map(h => tableHeader(h))}
                                <th data-field="Action"><span>Action</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            {props.groupReducer?.groups?.map((group, i) =>
                                <StreamGroup data={group} getGroups={()=>getGroups()} key={group.GroupId} index={i}/>)}
                            {sortedUserChannels.map((channel, i) =>
                                <UserChannel data={channel} getUserChannels={()=>getUserChannels()} key={channel.SongId} index={i}/>)}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="bg-white app-footer-container d-flex px-3
                    justify-content-between align-items-center flex-wrap">
                    <div className="d-flex flex-wrap py-2 mr-3">

                        {/*Hien thi nut ve trang dau neu co nhieu hon 1 trang dang truoc*/}
                        <a href="#" className={"btn btn-icon btn-sm btn-light-primary mr-2 my-1" +
                        (props.userChannelReducer.currentIndex<=1?" disabled":"")}
                           onClick={()=> {changeCurrentIndex(1)}}>
                            <i className="fas fa-angle-double-left"/></a>
                        {/*Hien thi nut ve trang truoc neu co nhieu hon 1 trang dang truoc*/}
                        <a href="#" className={"btn btn-icon btn-sm btn-light-primary mr-2 my-1" +
                        (props.userChannelReducer.currentIndex<=1?" disabled":"")}
                           onClick={()=> {changeCurrentIndex(props.userChannelReducer.currentIndex-1)}}>
                            <i className="fas fa-angle-left"/></a>

                        {props.userChannelReducer.currentIndex>3&&(
                            // Neu nhieu hon 3 trang thi hien thi dau ... dang truoc
                            <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">...</a>
                        )}
                        {props.userChannelReducer.currentIndex>2&&(
                            // Neu nhieu hon 2 trang thi hien thi trang thu 2 truoc do
                            <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
                               onClick={()=> {changeCurrentIndex(props.userChannelReducer.currentIndex-2)}}>
                                {props.userChannelReducer.currentIndex-2}</a>
                        )}
                        {props.userChannelReducer.currentIndex>1&&(
                            // Neu nhieu hon 1 trang thi hien thi trang thu 1 truoc do
                            <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
                               onClick={()=> {changeCurrentIndex(props.userChannelReducer.currentIndex-1)}}>
                                {props.userChannelReducer.currentIndex-1}</a>
                        )}

                        {/*Trang dang chon*/}
                        <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1"
                           onClick={()=> {changeCurrentIndex(props.userChannelReducer.currentIndex)}}>
                            {props.userChannelReducer.currentIndex}</a>

                        {props.userChannelReducer.currentIndex<maxIndex&&(
                            // Neu con nhieu hon 1 trang thi hien thi trang thu 1 sau do
                            <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
                               onClick={()=> {changeCurrentIndex(props.userChannelReducer.currentIndex+1)}}>
                                {props.userChannelReducer.currentIndex+1}</a>
                        )}

                        {props.userChannelReducer.currentIndex+1<maxIndex&&(
                            // Neu con nhieu hon 2 trang thi hien thi trang thu 2 sau do
                            <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
                               onClick={()=> {changeCurrentIndex(props.userChannelReducer.currentIndex+2)}}>
                                {props.userChannelReducer.currentIndex+2}</a>
                        )}

                        {props.userChannelReducer.currentIndex+2<maxIndex&&(
                            // Neu con nhieu hon 3 trang thi hien thi dau ...
                            <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">...</a>
                        )}

                        {/*Hien thi nut sang trang tiep neu co nhieu hon 1 trang dang sau*/}
                        <a href="#" className={"btn btn-icon btn-sm btn-light-primary mr-2 my-1" +
                        (props.userChannelReducer.currentIndex>=maxIndex?" disabled":"")}
                           onClick={()=> {changeCurrentIndex(props.userChannelReducer.currentIndex+1)}}>
                            <i className="fas fa-angle-right"/></a>
                        {/*Hien thi nut sang trang cuoi neu co nhieu hon 1 trang dang sau*/}
                        <a href="#" className={"btn btn-icon btn-sm btn-light-primary mr-2 my-1" +
                        (props.userChannelReducer.currentIndex>=maxIndex?" disabled":"")}
                           onClick={()=> {changeCurrentIndex(maxIndex)}}>
                            <i className="fas fa-angle-double-right"/></a>
                    </div>
                    <div className="d-flex align-items-center py-3">
                        {props.userChannelReducer.loading&&(
                            <div className="d-flex align-items-center">
                                <div className="mr-2 text-muted">Loading...</div>
                                <div className="spinner spinner-primary mr-10"/>
                            </div>
                        )}

                        <form className="d-flex align-items-center"
                              onSubmit={onSubmitGoToPage}>
                            <label className="mb-0 mr-2">Go to</label>
                            <input id="currentIndex" type="number" value={currentIndex}
                                   onChange={(e) => {
                                       // Validate current index
                                       if (e.target.value <= 0) {
                                           setCurrentIndex(1);
                                       } else if (e.target.value > maxIndex) {
                                           setCurrentIndex(maxIndex)
                                       } else {
                                           setCurrentIndex(e.target.value)
                                       }
                                   }}
                                   className="form-control mr-4" placeholder="Enter page"
                                   style={{width:"100px"}}/>
                        </form>

                        <Dropdown className="mr-3">
                            <Dropdown.Toggle id="dropdown-basic" className="btn btn-secondary">
                                {props.userChannelReducer.currentDisplayingLimit}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onSelect={(eventKey, e) => {
                                    dispatch({type: UserChannelAction.CHANGE_DISPLAY_LIMIT, payload: Number(PagingDisplay.DISPLAY_LIMIT_20)});
                                }}>{PagingDisplay.DISPLAY_LIMIT_20}</Dropdown.Item>
                                <Dropdown.Item onSelect={(eventKey, e) => {
                                    dispatch({type: UserChannelAction.CHANGE_DISPLAY_LIMIT, payload: Number(PagingDisplay.DISPLAY_LIMIT_50)});
                                }}>{PagingDisplay.DISPLAY_LIMIT_50}</Dropdown.Item>
                                <Dropdown.Item onSelect={(eventKey, e) => {
                                    dispatch({type: UserChannelAction.CHANGE_DISPLAY_LIMIT, payload: Number(PagingDisplay.DISPLAY_LIMIT_100)});
                                }}>{PagingDisplay.DISPLAY_LIMIT_100}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <span className="text-muted mr-3">{props.userChannelReducer.userChannelsCount} channels</span>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        // Gan channel reducer vao props
        userChannelReducer:state.userChannelReducer,
        accountReducer:state.accountReducer,
        groupReducer:state.groupReducer,
    };
};

export default connect(mapStateToProps) (MyStreams);
