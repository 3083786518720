import {TransactionAction} from "../constants";
/**
    {
        "Id": "97",
        "SenderId": "40",
        "TargetId": "17678",
        "Amount": -100,
        "Type": "2",
        "PaymentSource": null,
        "Description": "Chiến Nguyễn donated 100 coins to TrieuQuy",
        "TransactionId": null,
        "Time": "2021-05-18 08:57:26"
    }
 * @type {{transactions: *[]}}
 */
const initialState = {
    transactions: [],
};

// Xu ly cac su kien transaction
export default function transactionReducer(state = initialState, action) {
    switch (action.type) {
        case TransactionAction.GET_TRANSACTIONS: {
            return {
                ...state,
                ...action.payload,
                transactions: action.payload,
            }
        }
        default:
            return state;
    }
}
