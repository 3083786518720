import React, {Component, useState, useEffect, createRef} from 'react'
import {connect, useDispatch} from "react-redux";
import {getMessageList, sendMessage} from "../../../../services/ws";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Message from "../../../components/Message";
import {TokenKey, TokenService} from "../../../../services/storageService";
import {MessageAction, PagingDisplay, UserChannelAction, validateEmail} from "../../../../store/constants";

const MessageInfo = (props) => {
    // User id cua user message
    let { id } = useParams();
    let focusInputMessage = createRef();
    let [currentConversation, setCurrentConversation] = useState()
    const dispatch = useDispatch();
    const [inputMessage, setInputMessage] = useState("");
    const [inputMessageError, setInputMessageError] = useState("");

    const messagesEndRef = createRef()

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        // Lay danh sach message
        getMessageList()
        focusInputMessage.current.focus();
    }, []);


    useEffect(() => {
        // Tim kiem conversation ung voi id
        (props.messageReducer.messageList||[]).forEach(value => {
            if (value.ToUser.UserId == id) {
                setCurrentConversation(value)
            }
        });
        scrollToBottom();
    }, [props.messageReducer.messageList])

    useEffect(() => {
        if (props.messageReducer.sendMessageResult === true) {
            setInputMessage("")
            dispatch({type: MessageAction.SEND_MESSAGE_RESULT, payload: null});
        }
    }, [props.messageReducer.sendMessageResult])

    const onSubmit = async (event) => {
        event.preventDefault();
        let isValid = true;
        if (!inputMessage) {
            setInputMessageError(" is-invalid ")
            isValid = false;
        } else {
            setInputMessageError("")
        }
        if (isValid) {
            // Gui tin nhan
            sendMessage(inputMessage, currentConversation.ToUser.UserId)
        }
    }

    // Hien thi danh sach message
    return (
        <div className="d-flex flex-column h-100">
            <div className="channel-row mt-3">
                {currentConversation?.Messages?.map(value => <Message key={value.MessageId} data={value}/>)}
                <div ref={messagesEndRef} />
            </div>
            <form className="fixed-bottom position-sticky mt-auto" style={{bottom: "16px"}} onSubmit={onSubmit}>
                <div className="row m-0">
                    <div className="col pl-0">
                        <input ref={focusInputMessage} className={`form-control ${inputMessageError}`}
                           id="input-message" placeholder="Enter a message"
                           onChange={(event) => setInputMessage(event.target.value)}
                           value={inputMessage} />
                    </div>
                    <button type="submit" className="btn btn-primary">Send</button>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        // Gan reducer vao props
        messageReducer:state.messageReducer,
    };
};

export default connect(mapStateToProps) (MessageInfo);
