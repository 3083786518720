const axios = require('axios');
import { logout } from '../store/actions/authActions';
import { TokenService } from './storageService'
import store from "../store/index";
import Swal from "sweetalert2";

const ApiService = {
    init(baseURL = process.env.REACT_APP_API_URL) {
        axios.defaults.baseURL = baseURL;
        this.mount401Interceptor();
        if (TokenService.getToken()) {
            this.setHeader();
            this.initCommonData();
        }
    },

    setHeader() {
        // axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`;
    },

    removeHeader() {
        // axios.defaults.headers.common["Authorization"] = {};
    },

    get(resource) {
        if (TokenService.getToken()) {
            this.setHeader();
        }
        return axios.get(resource);
    },

    async getByUrl(url, config) {
        return await axios.get(url, config)
    },

    async post(resource, data) {
        return await axios.post(resource, data);
    },

    postNoAsync(resource, data) {
        return axios.post(resource, data)
    },

    getAxios() {
        return axios;
    },

    put(resource, data) {
        return axios.put(resource, data);
    },

    delete(resource) {
        return axios.delete(resource);
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     **/
    request(data) {
        return axios(data);
    },

    postFormUrlencoded(datas, resource, params = new URLSearchParams()) {
        datas.forEach(data => {
            params.append(data.key, data.value);
        })
        return axios.post(resource, params);
    },

    _401interceptor: null,
    mount401Interceptor() {
        this._401interceptor = axios.interceptors.response.use(
            (response) => {
                if (response?.code === 5) {
                    store.dispatch(logout());
                }
                return response
            }, function (error) {
                if (error) {
                    let responseData = error?.response?.data;
                    if (responseData && responseData.Code === 5) {
                        store.dispatch(logout());
                        return;
                    }
                    if (error.request.status === 0) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Server is not reachable',
                            showConfirmButton: false,
                            timer: 3000,
                            toast: true,
                            position: 'top-end',
                            timerProgressBar: true,
                        })
                    }
                    TokenService.removeToken()
                }
                return Promise.reject(error)
            }
        )
    },

    unmount401Interceptor() {
        // Eject the interceptor
        axios.interceptors.response.eject(this._401interceptor)
    },

    initCommonData() {
    }
}

export default ApiService
