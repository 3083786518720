import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import {connect, useDispatch} from "react-redux";
import {TokenKey, TokenService} from "../../../services/storageService";
import ApiService from "../../../services/apiService";
import {StatisticsAction, sort, isTesting} from "../../../store/constants";
import {Col, Row} from "react-bootstrap";
import ChannelDropdown from "../../components/ChannelDropdown";
import CountryDropdown from "../../components/CountryDropdown";
import DateRangeDropdown from "../../components/DateRangeDropdown";
import {addDays, addMonths} from "date-fns";
import moment from "moment";

const Statistics = (props) => {

    let dispatch = useDispatch();
    let [datesArray, setDatesArray] = useState([]);
    let [checkedSongIds, setCheckedSongIds] = useState([]);
    let [checkedCountryCodes, setCheckedCountryCodes] = useState([]);
    let [options, setOptions] = useState({
        chart: {
            id: 'streamsStatistics',
        },
        xaxis: {
            categories: [],
            type: 'datetime',
        },
        stroke: {
            curve: 'smooth',
        },
        markers: {
            size: 0,
        }
    });
    let [series, setSeries] = useState([{
        name: 'Views',
        data: []
    }]);

    let [dateRange, setDateRange] = useState([
        {
            startDate: addMonths(new Date(), -1),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    useEffect(() => {
        // Lay danh sach stream statistics
        getStreamStatistics();
    }, [dateRange])

    // Lay danh sach stream statistics
    const getStreamStatistics = async () => {
        if (dateRange != null && dateRange.length > 0) {
            let startDate = moment(dateRange[0].startDate).format("YYYY-MM-DD");
            let endDate = moment(dateRange[0].endDate).format("YYYY-MM-DD");
            try {
                let postData = {
                    params: {
                        UserId: TokenService.getToken(TokenKey.USER_ID),
                        Token: TokenService.getToken(),
                        StartDate: startDate,
                        EndDate: endDate
                    }
                };
                if (isTesting) {
                    postData = {
                        params: {
                            UserId: "9557418",
                            Token: "010e3b136c7117b98124c46b4605f3a90a10b04f51ba724122015124cfce9f21",
                            StartDate: startDate,
                            EndDate: endDate
                        }
                    };
                }
                let streamStatisticsData = await ApiService.getByUrl('/stream_v2/get_streams_statistics.php', postData);
                // Luu lai danh sach stream statistics
                dispatch({
                    type: StatisticsAction.GET_STREAM_STATISTICS,
                    payload: streamStatisticsData.data
                });
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        // Set danh sach ngay
        setDatesArray(Object.keys(props.statisticsReducer.StreamStatistics)||[]);
    }, [props.statisticsReducer.StreamStatistics]);

    // Neu set duoc danh sach ngay thi hien thi
    useEffect(() => {
        setOptions({
            ...options,
            xaxis: {
                ...options.xaxis,
                categories: (datesArray||[]),
            }
        });
        // Tinh tong luot view
        let counts = [];
        let streamStatistics = props.statisticsReducer.StreamStatistics;
        (Object.keys(streamStatistics)||[]).map(date => {
            let count = 0;
            (Object.keys(streamStatistics[date])||[]).map(songId => {
                // Tinh theo channel da chon
                if (checkedSongIds.includes(songId)) {
                    // Tinh theo country da chon
                    (Object.keys(streamStatistics[date][songId])||[]).map(countryCode => {
                        if (checkedCountryCodes.includes(countryCode)) {
                            count += Number(streamStatistics[date][songId][countryCode]);
                        }
                    });
                }
            });
            counts.push(count);
        });
        setSeries([{
            name: 'Views',
            data: (counts||[])
        }]);
    }, [datesArray, checkedSongIds, checkedCountryCodes]);

    return (
        <>
            <div className="card card-custom p-3">
                <h3 className="card-label font-weight-bolder text-dark" style={{fontSize: "1.275rem"}}>Stream Statistics</h3>
                <form onSubmit={(e) => {
                }}>
                    <Row>
                        <ChannelDropdown checkedCountryCodes={checkedCountryCodes}
                            onCheckSongs={(checkedSongIds)=>{setCheckedSongIds(checkedSongIds)}}/>
                        <CountryDropdown checkedSongIds={checkedSongIds}
                            onCheckCountries={(checkedCountryCodes)=>{setCheckedCountryCodes(checkedCountryCodes)}}/>
                        <DateRangeDropdown dateRange={dateRange} setDateRange={(d) => setDateRange(d)} checkedSongIds={checkedSongIds}
                            checkedCountryCodes={checkedCountryCodes} />
                    </Row>
                </form>
                <Chart
                    options={options}
                    series={series}
                    type="line"
                    height="600px"
                />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        // Gan reducer vao props
        statisticsReducer:state.statisticsReducer,
    };
};

export default connect(mapStateToProps) (Statistics);
