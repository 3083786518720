import React, {Component, useState, useEffect} from 'react'
import {ActionType, GroupAction, sort, UserChannelAction} from "../../../../store/constants";
import {connect, useDispatch} from "react-redux";
import UserChannel from "../../../components/UserChannel";
import {Link, useParams} from "react-router-dom";
import ApiService from "../../../../services/apiService";
import Swal from "sweetalert2";
import {TokenKey, TokenService} from "../../../../services/storageService";

const GroupInfo = (props) => {

    const dispatch = useDispatch();
    // Id cua group
    let { id } = useParams();

    const [currentGroup, setCurrentGroup] = useState();
    const [sortedColumn, setSortedColumn] = useState("Name");
    const [descending, setDescending] = useState(false);
    // Danh sach cac stream tim kiem duoc dua vao stream ids
    const [sortedStreams, setSortedStreams] = useState([]);

    // Lay danh sach group khi reload lai trang
    useEffect(()=>{
        getGroups()
    }, [])

    // Lay group hien tai
    useEffect(()=>{
        let group = props.groupReducer.groups?.find(g => g.GroupId==id);
        if (group) {
            setCurrentGroup(group)
        }
    },[props.groupReducer.groups]);

    // Sap xep danh sach streams
    useEffect(() => {
        if (sortedColumn) {
            setSortedStreams(sort(currentGroup?.Streams, sortedColumn, descending));
        } else {
            setSortedStreams(currentGroup?.Streams);
        }
    }, [currentGroup]);

    const setSort = (property) => {
        setSortedColumn(property);
        if (sortedColumn === "" || sortedColumn !== property) {
            // Sap xep lan dau tang dan
            setSortedStreams(sort(currentGroup?.Streams, property, true));
            setDescending(true);
        } else {
            // Sap xep nguoc lai
            setSortedStreams(sort(currentGroup?.Streams, property, !descending));
            setDescending(!descending);
        }
    }

    // Lay danh sach user channel va group
    const getUserChannels = async () => {
        try {
            dispatch({
                type:UserChannelAction.LOADING,
                payload:{}
            })
            // Lay user channel
            let postData = {
                params: {
                    TargetId: props.accountReducer.User?.UserId,
                    StartIndex: (props.userChannelReducer.currentIndex - 1) * props.userChannelReducer.currentDisplayingLimit,
                    MaxResult: props.userChannelReducer.currentDisplayingLimit
                }
            };
            let userChannelData = await ApiService.getByUrl('/stream_v2/get_streams_of_user.php', postData);
            // Luu lai danh sach channel, so luong trang
            dispatch({
                type:UserChannelAction.GET_USER_CHANNELS,
                payload:{
                    userChannels:userChannelData.data.UserChannels,
                    count:userChannelData.data.TotalChannel,
                }
            });

            if (userChannelData.data.Result === ActionType.RESULT_FAILED) {
                // Khong tim thay channel nao
                Swal.fire({
                    icon: 'error',
                    title: `Server error ${userChannelData.data.Reason}`
                });
            }
            // Lay danh sach group
            await getGroups()
        } catch (error) {
            console.log(error);
        }
    }

    // Lay danh sach group
    const getGroups = async () => {
        // Lay group
        let postData = {
            params: {
                Action: "Get",
                UserId: TokenService.getToken(TokenKey.USER_ID),
                Token: TokenService.getToken()
            }
        };
        let groupData = await ApiService.getByUrl('/stream_v2/group.php', postData);
        // Luu lai danh sach group
        dispatch({
            type:GroupAction.GET_GROUPS,
            payload:groupData.data.Groups,
        });
    }

    const tableHeader = (header) => <th key={header.Name} className={`${(sortedColumn === header.Sort)?"text-primary":""}`}
        data-field={header.Name} style={{cursor:"pointer"}}
        onClick={(e)=>setSort(header.Sort)}>
        <span className={(sortedColumn===header.Sort)?"text-primary":""}>{header.Name}</span>
        {sortedColumn===header.Sort&&<i className={`text-primary ml-2 far fa-1x ${!descending?"fa-arrow-down":" fa-arrow-up"}`}/>}
    </th>

    const tableHeaders = [
        {Name: "Code", Sort: "Code"},
        {Name: "Name", Sort: "Name"},
        {Name: "URL", Sort: "URL"},
        {Name: "Language", Sort: "Language"},
        {Name: "Views", Sort: "ViewByAll"},
        {Name: "LastOnline", Sort: "LastOnline"}]

    // Hien thi danh sach channel
    return (
        <>
            <div className="card card-custom">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center m-3">
                        <h3 className="card-label font-weight-bolder text-dark mb-0" style={{fontSize: "1.275rem"}}>{currentGroup?.GroupName} ({currentGroup?.Streams?.length})</h3>
                    </div>
                    <div className="d-flex align-items-center m-3">
                        <Link to={{ pathname: "/stream/create" }}>
                            <div className="btn btn-primary font-weight-bolder mr-3">
                                Add Stream
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="card-body p-3 table-responsive">
                    <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded"
                         id="kt_datatable">
                        <table className="table mb-0">
                            <thead>
                            <tr className="text-nowrap">
                                {tableHeaders.map(h => tableHeader(h))}
                                <th data-field="Action"><span>Action</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            {sortedStreams?.map((channel, i) =>
                                <UserChannel data={channel} getUserChannels={()=>getUserChannels()} group={currentGroup} key={channel.SongId} index={i}/>)}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="bg-white app-footer-container d-flex px-3
                    justify-content-between align-items-center flex-wrap">
                    <div className="d-flex flex-wrap py-2 mr-3">
                    </div>
                    <div className="d-flex align-items-center py-3">
                        <span className="text-muted mr-3">{currentGroup?.Streams?.length} channels</span>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        // Gan channel reducer vao props
        userChannelReducer:state.userChannelReducer,
        accountReducer:state.accountReducer,
        groupReducer:state.groupReducer,
    };
};

export default connect(mapStateToProps) (GroupInfo);
