import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from "react-redux";
import {TokenKey, TokenService} from "../../services/storageService";

/**
{
    "Id": "97",
    "SenderId": "40",
    "TargetId": "17678",
    "Amount": -100,
    "Type": "2",
    "PaymentSource": null,
    "Description": "Chiến Nguyễn donated 100 coins to TrieuQuy",
    "TransactionId": null,
    "Time": "2021-05-18 08:57:26"
}
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Transaction = (props) => {

    let transaction = props.data
    let giaoDich = "Buy Coin";
    let soLuongColor = "text-success"
    if (transaction.Type == 2) {
        giaoDich = "Donate Coin";
        soLuongColor = "text-danger"
    } else if (transaction.Type == 3) {
        giaoDich = "Buy Product";
        soLuongColor = "text-danger"
    }

    return (
        <tr>
            <td data-field="giao_dich" className="align-middle">
                <span className="text-secondary">{giaoDich}</span>
            </td>
            <td data-field="noi_dung" className="align-middle">
                <span className="text-secondary">{transaction.Description}</span>
            </td>
            <td data-field="so_luong" className="align-middle">
                <span className={`${soLuongColor}`}>{transaction.Amount}</span>
            </td>
            <td data-field="thoi_diem" className="align-middle">
                <span className="text-secondary">{transaction.Time}</span>
            </td>
        </tr>
    )
}

const mapStateToProps = (state) => {
    return {
        userChannelReducer:state.userChannelReducer,
        accountReducer:state.accountReducer
    };
};

export default connect(mapStateToProps) (Transaction);
