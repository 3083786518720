import {FollowAction} from "../constants";

const initialState = {
    followings: [],
    followers: [],
};

// Xu ly cac su kien follow, unfollow
export default function (state = initialState, action) {
    switch (action.type) {
        case FollowAction.GET_FOLLOWINGS:
            return {
                ...state,
                followings: action.payload,
            }
        case FollowAction.GET_FOLLOWERS:
            return {
                ...state,
                followers: action.payload,
            }
        case FollowAction.UNFOLLOW: {
            let {followId} = action.payload;
            if (followId) {
                return {
                    ...state,
                    followings: [...state.followings].map(following => {
                        if (following.UserId == followId) {
                            following.DeletedAt = new Date();
                        }
                        return following;
                    }),
                }
            }
            return state;
        }
        case FollowAction.FOLLOW: {
            let {followId} = action.payload;
            if (followId) {
                let followings = [...state.followings];
                let followUser = followings.find(following => {
                    return following.UserId == followId;
                });
                if (followUser) {
                    delete followUser.DeletedAt;
                } else {
                    followUser = [...state.followers].find(follower => {
                        return follower.UserId == followId;
                    });
                    if (followUser) {
                        followings.unshift(followUser);
                    }
                }
                return {
                    ...state,
                    followings,
                }
            }
            return state;
        }
        default:
            return state;
    }
}
