import React, { Component, useState, useEffect } from 'react';
import {connect, useDispatch} from "react-redux";
import NoAvatar from "../../../assets/images/noavatar.jpg";
import {Col, Row} from "react-bootstrap";
import {TokenKey, TokenService} from "../../../services/storageService";
import {ActionType, TransactionAction} from "../../../store/constants";
import ApiService from "../../../services/apiService";
import Transaction from "../../components/Transaction";

const TransactionHistory = (props) => {
    let dispatch = useDispatch()
    // Danh sach transaction
    let [transactions, setTransactions] = useState(props.transactionReducer.transactions)
    let user = props.accountReducer.User;
    // Lay danh sach transaction
    useEffect(async () => {
        let postData = {
            params: {
                TargetId: TokenService.getToken(TokenKey.USER_ID),
                Token: TokenService.getToken(),
                StartIndex: 0
            }
        };
        let {data} = await ApiService.getByUrl("https://edge.mdcgate.com/sales/coin/get_coin_transaction.php", postData)
        if (data.Result == ActionType.RESULT_1) {
            // Lay transaction thanh cong, luu lai danh sach transaction
            dispatch({type: TransactionAction.GET_TRANSACTIONS, payload: data.Transactions})
        }
    }, [])

    useEffect(async () => {
        setTransactions(props.transactionReducer.transactions)
    }, [props.transactionReducer.transactions])

    return (
        <>
            <div className="card card-custom p-3">
                <div className="justify-content-start align-items-center d-flex flex-wrap">
                    {/*Avatar*/}
                    {props.accountReducer.isAuthenticated ?
                            <div className="symbol symbol-circle symbol-sm-100 symbol-40 mb-3">
                                <img alt="Pic" src={`${user?.Avatar ? user.Avatar : NoAvatar}`}
                                     onError={(e) => e.target.src = NoAvatar}
                                     style={{objectFit:'cover'}}/>
                            </div> : ""
                    }
                    <Col>
                        {/*Email*/}
                        {props.accountReducer.isAuthenticated? <>
                            <h3 className="card-label text-dark m-0"
                                style={{fontSize: "1.275rem"}}>{user?.Email}</h3>
                        </>:""}
                        {/*Status*/}
                        {props.accountReducer.isAuthenticated? <>
                            <h3 className="card-label text-dark m-0"
                                style={{fontSize: "1.275rem"}}>{user?.StatusMessage}</h3>
                        </>:""}
                    </Col>
                    {props.accountReducer.isAuthenticated? <>
                        <Col className="p-0">
                            <Row className="m-0">
                                <Col className="text-nowrap p-0"><h3 className="card-label text-dark mr-3" style={{fontSize: "1.275rem"}}>Total your coin</h3></Col>
                                <Col className="text-nowrap p-0"><h3 className="card-label text-dark m-0" style={{fontSize: "1.275rem"}}>{user?.TotalCoin}</h3></Col>
                            </Row>
                            <Row>
                                <Col className="text-nowrap p-0"/>
                                <Col className="text-nowrap p-0"><h3 className="card-label text-success m-0" style={{fontSize: "1.275rem"}}>{(user?.TotalCoin/10).toFixed(1)}$</h3></Col>
                            </Row>
                        </Col>
                    </>:""}
                </div>
                <h3 className="card-label text-dark mt-3">Transaction History</h3>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr className="text-nowrap">
                                <th data-field="giao_dich"><span>Transaction Type</span></th>
                                <th data-field="noi_dung"><span>Content</span></th>
                                <th data-field="so_luong"><span>Amount</span></th>
                                <th data-field="thoi_diem"><span>Time</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions?.map((transaction, i) =>
                                <Transaction data={transaction} key={i}/>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        accountReducer: state.accountReducer,
        transactionReducer: state.transactionReducer,
    };
};

export default connect(mapStateToProps)(TransactionHistory);

