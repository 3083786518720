import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from "react-redux";
import {Link, useHistory} from "react-router-dom";

const Conversation = (props) => {

    let [userAvatar, setUserAvatar] = useState();

    useEffect(()=>{
        // Set image
        if (props.data.ToUser.Avatar) {
            setUserAvatar(
                <img className="channel-image w-100"
                    src={props.data.ToUser.Avatar} alt="User Avatar"
                    onError={(e)=>{
                        setUserAvatar(noAvatarText);
                    }}/>
            );
        } else {
            setUserAvatar(noAvatarText);
        }
    },[])

    let noAvatarText = (
        <>
            <div
                 className="channel-image w-100 background-f5f5f5 d-flex justify-content-center align-items-center">
                <span className="text-muted font-weight-bold">No Avatar</span>
            </div>
        </>
    );

    return (
        <>
            <div className="col-6 col-md-4 col-xl-3 px-3 cursor-pointer">
                <Link to={{
                    pathname: `./messages/${props.data.ToUser.UserId}`
                }}>
                    <div className="card card-custom gutter-b card-stretch p-3">
                        <div className="d-flex h-100 flex-column">
                            {userAvatar}
                        </div>
                        <div className="w-100 d-flex align-items-center mt-3 justify-content-center">
                            {/*Ten nguoi nhan*/}
                            <span className="text-secondary text-2-line font-weight-bold text-break">{props.data?.ToUser?.Nickname??""}&nbsp;
                                ({props.data?.Messages?.length??""})</span>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userChannelReducer:state.userChannelReducer,
        accountReducer:state.accountReducer
    };
};

export default connect(mapStateToProps) (Conversation);
