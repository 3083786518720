import {StatisticsAction} from "../constants";

/**
 * {
    "StatisticsStream": {
        "2020-12-30": {
            "ESP": 91,
            "FRA": 17,
        },
        "2021-01-04": {
            "USA": 556,
            "CAN": 181,
        },
        "2021-01-08": {
            "FRA": 69,
            "GBR": 329,
            "ITA": 34,
        },
    "Songs": {
        "992010": "test block chien",
        "994303": "mdc test chien 12345678",
        "1053067": "[ustvgo] motortrend",
        "1060027": "ustv247",
        "1074913": "123tvnow2",
        "1075901": "showtime",
        "1130821": "buffstreams"
    }
 */
const initialState = {
    StreamStatistics: {},
    Songs: [],
    Countries: [],
};

// Xu ly cac su kien statistics
export default function statisticsReducer(state = initialState, action) {
    switch (action.type) {
        case StatisticsAction.GET_STREAM_STATISTICS: {
            return {
                ...state,
                ...action.payload,
                StreamStatistics: (action.payload.StatisticsStream||{}),
                Songs: action.payload.Songs,
                Countries: action.payload.Countries,
            }
        }
        default:
            return state;
    }
}
