import React, {Component, createRef, useEffect, useState} from 'react'
import {validateEmail, ActionType} from "../../../store/constants";
import { connect, useDispatch, useSelector } from 'react-redux'
import { TokenKey, TokenService } from "../../../services/storageService";
import ApiService from "../../../services/apiService";
import Swal from "sweetalert2";
import {Link, Redirect, useHistory, useLocation} from "react-router-dom";
import md5 from 'crypto-js/md5'
import {loginWebsocket} from "../../../services/ws";
import signUpBackground from "../../../assets/images/sign_up_background.jpg"
import styled from "styled-components";
import Logo from "../../../assets/images/mdc_logo.png";

const FooterStyle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #9EA5BD;
`

const Login = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    let focusEmail = createRef();

    useEffect(() => {
        if (focusEmail.current != null) {
            focusEmail.current.focus();
        }
    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();
        if (!validate()) return;
        console.log(`Email ${email} is logging`)
        try {
            const { data } = await ApiService.getByUrl(`/user/login.php`, {
                params: {
                    "Type": 0,
                    "Email": email,
                    "Password": md5(password).toString()
                }
            });
            if (data.Result === ActionType.RESULT_SUCCESS) {
                console.log(`Email ${email} logged in, data: ${JSON.stringify(data)}`);
                // Dang nhap thanh cong thi luu lai tai khoan, mat khau
                TokenService.saveToken(email, TokenKey.EMAIL);
                TokenService.saveToken(password, TokenKey.PASSWORD);
                // Luu lai token
                TokenService.saveToken(data.User.Token);
                ApiService.setHeader();
                // Dispatch dang nhap thanh cong
                dispatch({ type: ActionType.LOGIN_SUCCESS, payload: data });
                ApiService.initCommonData();
                // Luu lai user id
                TokenService.saveToken(data.User.UserId, TokenKey.USER_ID)
                // Dang nhap websocket
                loginWebsocket(data.User.UserId, data.User.Token)
                // Chuyen sang man hinh home
                history.push("/stream");
            } else {
                console.log(`Email ${email} login with password ${md5(password).toString()} error: ${data.Reason}`);
                Swal.fire({
                    icon: 'error',
                    title:data.Reason,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const validate = () => {
        let isValid = true;
        if (!validateEmail(email)) {
            setEmailError("Email is invalid")
            isValid = false;
        } else {
            setEmailError("")
        }
        if (!password.match(".+")) {
            setPasswordError("Password is invalid")
            isValid = false;
        } else {
            setPasswordError("")
        }
        return isValid;
    }

    const [email, setEmail] = useState(TokenService.getToken(TokenKey.EMAIL) ?? '');
    const [password, setPassword] = useState(TokenService.getToken(TokenKey.PASSWORD) ?? '');

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [remember, setRemember] = useState(TokenService.getToken(TokenKey.REMEMBER) ?? true);

    if (props.accountReducer.isAuthenticated) {
        // Neu da dang nhap chuyen qua trang chu
        return <Redirect to="/stream" />
    } else {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center main-root-content"
                style={{ backgroundImage: `url(${signUpBackground})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                <div className="card card-custom col-12 col-sm-6 col-md-4 col-xl-3
                    justify-content-center mx-auto">
                    <form onSubmit={onSubmit}>
                        <div className="card-body">
                            <h3 className="card-title">
                                Login to Your Account
                            </h3>
                            <div className="form-group mb-3">
                                <label className="mb-0" htmlFor="email">Email address</label>
                                <input ref={focusEmail} type="email" className="form-control"
                                       id="email" placeholder="Email"
                                       onChange={(event) => setEmail(event.target.value)}
                                       value={email} />
                                <div className="fv-plugins-message-container">
                                    <div data-field="username" data-validator="notEmpty" className="fv-help-block">
                                        {emailError}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label className="mb-0" htmlFor="password">Password</label>
                                <input type="password" className="form-control" id="password"
                                       placeholder="Password" autoComplete="on"
                                       onChange={(event) => setPassword(event.target.value)}
                                       value={password} />
                                <div className="fv-plugins-message-container">
                                    <div data-field="username" data-validator="notEmpty" className="fv-help-block">
                                        {passwordError}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <div className="checkbox-inline">
                                    <label className="checkbox">
                                        <input type="checkbox" name="rememberMe"
                                               checked={remember} onChange={() => setRemember(!remember)} />
                                        <span />
                                        Remember me
                                    </label>
                                    <span onClick={(e)=>{window.open( "https://edge.mdcgate.com/livemedia/user/reset_password.html")}} style={{ marginLeft: "auto" }}
                                          className="cursor-pointer text-primary my-0">Forgot password?</span>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mr-2 btn-block">Login</button>
                            <div style={{ textAlign: "center" }} className="mt-5">
                                <span className="text-dark-50">Don't have an account? </span>
                                <Link to="/signup" className="text-primary">Sign up</Link>
                            </div>
                        </div>
                    </form>
                </div>

                <FooterStyle>
                    <div className="Footer d-flex align-items-center mt-3">
                        <div className="container-fluid">
                            <div className="row">
                                <img alt="Logo" src={Logo} className="max-h-20px"/><div className="ml-3 text-white">© Copyright 2021 - MDC Media CO., LTD</div>
                            </div>
                        </div>
                    </div>
                </FooterStyle>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        // Gan account reducer vao props
        accountReducer: state.accountReducer
    };
};

export default connect(mapStateToProps)(Login);
