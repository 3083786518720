import ApiService from "../../services/apiService";
import {TokenKey, TokenService} from "../../services/storageService";
import {ActionType} from "../constants";

// logout
export const logout = (manual = true) => async (dispatch) => {
    TokenService.removeToken();
    if (manual) {
        TokenService.removeToken(ActionType.AUTO_LOGIN);
    }
    ApiService.removeHeader()
    ApiService.unmount401Interceptor()
    dispatch({
        type: ActionType.LOGOUT_SUCCESS
    });
    console.log(`Email ${TokenService.getToken(TokenKey.EMAIL)} logout success!`);
}
