import React, {Component, Suspense, useEffect, useState} from 'react'
import MobileHeader from "./MobileHeader";
import Sidebar from "./Sidebar";
import AppHeader from "./AppHeader";
import MainContent from "./MainContent";
import Footer from "./Footer";
import {connect, useDispatch} from "react-redux";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {SearchStreamAction, UserChannelAction} from "../../store/constants";
import Home from "../pages/home/Home";

require("../../theme/plugins/ktutil");
require("../../theme/plugins/ktoffcanvas");
require("../../theme/plugins/kttoggle");
require("../../theme/plugins/ktmenu");
require("../../theme/plugins/ktcookie");
import $ from 'jquery';

const Layout = (props) => {
    let location = useLocation().pathname;
    let dispatch = useDispatch();
    let history = useHistory();
    let [header, setHeader] = useState();

    useEffect(() => {
        if (location.match("/message\\.*")) {
            // Lay ten nguoi nhan tin hien tai
            let headerText = ""
            if (location) {
                let reg = /\d+/;
                let m;
                if (reg.test(location) && (m = reg.exec(location)) != null && m.length > 0) {
                    let id = m[0];
                    (props.messageReducer.messageList||[]).forEach(value => {
                        if (value.ToUser.UserId == id) {
                            headerText = value?.ToUser?.Nickname
                        }
                    })
                }
            }
            setHeader(<AppHeader canSearch={false} headerText={headerText} key={`message ${headerText}`}/>);
        } else if (location.match("/search\\.*")) {
            setHeader(<AppHeader canSearch={true} searchText={props.searchStreamReducer.searchStream}
                onSearch={(searchText)=>{
                    dispatch({ type: SearchStreamAction.SEARCH_STREAM, payload: searchText });
                    dispatch({ type: SearchStreamAction.CHANGE_CURRENT_INDEX, payload: 1 });
                }} key={`search`}/>);
        } else if (location.match("/stream\\.*")) {
            setHeader(<AppHeader canSearch={true} searchText={props.userChannelReducer.searchUserChannel}
                 onSearch={(searchText)=>{
                     dispatch({ type: UserChannelAction.SEARCH_USER_CHANNEL, payload: searchText });
                     dispatch({ type: UserChannelAction.CHANGE_CURRENT_INDEX, payload: 1 });
                 }} key={`stream`}/>);
        } else if(location.match(/\/profile(?=.*follow.*)/g)) {
            setHeader(<AppHeader/>)
        } else {
            setHeader("");
        }
        $('.aside-overlay').trigger('click');
    }, [location])

    if (history.location.pathname==="/") {
        // Neu chua dang nhap thi chuyen qua man dang nhap
        return <Home/>
    } else if (!props.accountReducer.isAuthenticated) {
        // Neu chua dang nhap thi chuyen qua man dang nhap
        return <Redirect to="/login" />
    } else {
        return (
            <div>
                <MobileHeader/>
                <Sidebar/>
                <div className="d-flex flex-column flex-root main-root-content">
                    <div className="d-flex flex-row flex-column-fluid page">
                        <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                            {header}
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="d-flex flex-column-fluid">
                                    <MainContent/>
                                </div>
                            </div>
                            <Footer/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        messageReducer: state.messageReducer,
        userChannelReducer: state.userChannelReducer,
        searchStreamReducer: state.searchStreamReducer,
        accountReducer:state.accountReducer
    };
};

export default connect(mapStateToProps) (Layout);
